import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'

import { useSelector } from 'react-redux'
import { hasContactMadeService } from '../../services/people'
import { useResponsiveness } from '../../hooks/responsive'
import FilterButton from '../../components/Header/FilterWithButton'

import { PeopleTableContactMadeButton } from './components'

import { getContactMadeButtonText } from './strategies/get-contact-made-button-text'
import { getHasContactedFilterParam } from './strategies/get-has-contacted-filter-param'

const useStyles = makeStyles({
  drawerButtonChip: {
    marginLeft: '10px',
    backgroundColor: 'white',
  },
  desktopButton: {
    right: 0,
    position: 'absolute',
    zIndex: 1100,
    display: 'flex',
    margin: '-3.5rem 20px',
    alignItems: 'center',
  },
  documentSearch: {
    width: 500,
    display: 'flex',
  },
  iconButtons: {
    height: 40,
  },
  mobileContactButton: {
    margin: '20px',
  },
})

export const PeopleTableHeader = ({
  selectedRows,
  afterHandleMadeContact,
  filteredBy,
}) => {
  const classes = useStyles()
  const [loading, isLoading] = useState(false)
  const { isMobile } = useResponsiveness()
  const { filterCounter } = useSelector(({ prospectTable }) => prospectTable)

  const handleContactMade = async () => {
    try {
      isLoading(true)
      const currentHasContactedState = getHasContactedFilterParam(filteredBy)
      const selectedRowsWithHasContactedProperty = selectedRows.map((person) => ({ ...person, hasContacted: !currentHasContactedState }))
      await hasContactMadeService(selectedRowsWithHasContactedProperty)
      await afterHandleMadeContact({ selectedPeople: selectedRowsWithHasContactedProperty })
      isLoading(false)
    } catch (e) {
      isLoading(false)
    }
  }

  const renderContactMadeButton = () => {
    if (selectedRows.length) {
      return (
        <PeopleTableContactMadeButton
          onClick={handleContactMade}
          isLoading={loading}
          buttonText={getContactMadeButtonText(filteredBy)}
          ButtonProps={{
            classes: {
              root: isMobile && classes.mobileContactButton,
            },
          }}
          ChipProps={{
            classes: {
              root: classes.drawerButtonChip,
            },
            label: selectedRows.length,
          }}
        />
      )
    }
  }

  if (isMobile) {
    return (
      <Drawer
        elevation={3}
        anchor="bottom"
        variant="persistent"
        open={selectedRows.length > 0}
      >
        {renderContactMadeButton()}
      </Drawer>
    )
  }
  return (
    <div className={classes.desktopButton}>
      <div className={classes.iconsContainer}>
        <FilterButton filterCounter={filterCounter} />
      </div>
      {renderContactMadeButton()}
    </div>
  )
}

PeopleTableHeader.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.any).isRequired,
  afterHandleMadeContact: PropTypes.func.isRequired,
}
