import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  customWrapper: {
    fontSize: 12,
    letterSpacing: 1,
    fontWeight: 500,
  },
  tabPanel: {
    height: '100%',
  },
}))
