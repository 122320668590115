import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles((theme) => ({
  filterGroupContainer: {
    paddingTop: theme.spacing(3),
  },
  filterSelectorContainer: {
    padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
  },
  inactiveSelectItem: {
    color: theme.palette.text.secondary,
  },
  filterSelectIcon: {
    color: theme.palette.text.primary,
  },
  selectOptionDisabled: {
    cursor: 'auto',
  },
}))
