import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { TextField, FormControl } from '@naturacosmeticos/natds-web'
import { useIntl } from 'react-intl'
import { Card } from '@material-ui/core'
import Select from '../Select/Select'
import { DocumentType } from './DocumentType'
import { style, useStyles } from './DocumentSearch.styles'
import { getLengthByDocumentType } from './documentSearch.utils'
import { ProspectTableActions } from '../../redux/combinedActions'
import { Icon } from '../Icon'
import { useGetDocumentsSWR } from '../../hooks/swrServices/use-get-documents-swr'
import FilterWithButton from '../Header/FilterWithButton'
import { useResponsiveness } from '../../hooks/responsive'

const selectPlaceholderId = 'select-document-placeholder'

function DocumentSearch() {
  const { isMobile } = useResponsiveness()
  const { filters } = useSelector(({ prospectTable }) => prospectTable)

  const [selectedDocument, setSelectedDocument] = useState(selectPlaceholderId)
  const [typedDocument, setTypedDocument] = useState('')
  const [isSearchPerformed, setIsSearchPerformed] = useState(false)
  const { documents } = useGetDocumentsSWR([DocumentType.PERSON_CODE])
  const classes = useStyles()
  const dispatch = useDispatch()

  const length = getLengthByDocumentType(selectedDocument)
  const intl = useIntl()
  const inputPlaceHolder = intl.formatMessage({
    id: 'searchByDocument',
    defaultMessage: 'Search by document',
  })

  const availableOptions = {
    [DocumentType.NATIONAL_REGISTRY]: {
      id: DocumentType.NATIONAL_REGISTRY,
      optionName: intl.formatMessage({
        id: 'nationalRegistry',
        defaultMessage: 'DNI',
      }),
    },
    [DocumentType.PASSPORT]: {
      id: DocumentType.PASSPORT,
      optionName: intl.formatMessage({
        id: 'passport',
        defaultMessage: 'Pasaporte',
      }),
    },
    [DocumentType.FOREIGN_REGISTRY]: {
      id: DocumentType.FOREIGN_REGISTRY,
      optionName: intl.formatMessage({
        id: 'foreignRegistry',
        defaultMessage: 'Carnet de Extranjeria',
      }),
    },
    [DocumentType.PERSON_CODE]: {
      id: DocumentType.PERSON_CODE,
      optionName: intl.formatMessage({
        id: 'personCode',
        defaultMessage: 'Person Code',
      }),
    },
    [DocumentType.CPF]: {
      id: DocumentType.CPF,
      optionName: 'CPF',
    },
    [DocumentType.MY_CAD]: {
      id: DocumentType.MY_CAD,
      optionName: intl.formatMessage({
        id: 'myCad',
        defaultMessage: 'MyCad',
      }),
    },
    [DocumentType.PASSPORT_MALASYA]: {
      id: DocumentType.PASSPORT_MALASYA,
      optionName: intl.formatMessage({
        id: 'passportMalasya',
        defaultMessage: 'Passaport Malasya',
      }),
    },
    [DocumentType.RUT]: {
      id: DocumentType.RUT,
      optionName: intl.formatMessage({
        id: 'rutChile',
        defaultMessage: 'RUT',
      }),
      regexValidation: /e|-|\W|\+|_|[a-j]|[l-z]/g,
    },
    [DocumentType.CITIZEN_CARD]: {
      id: DocumentType.CITIZEN_CARD,
      optionName: intl.formatMessage({
        id: 'citizenCard',
        defaultMessage: 'Citizen Card',
      }),
    },
    [DocumentType.FOREIGN_CARD]: {
      id: DocumentType.FOREIGN_CARD,
      optionName: intl.formatMessage({
        id: 'foreignCard',
        defaultMessage: 'Foreign Card',
      }),
    },
    [DocumentType.DNI_ARGENTINA]: {
      id: DocumentType.DNI_ARGENTINA,
      optionName: intl.formatMessage({
        id: 'dni',
        defaultMessage: 'DNI',
      }),
    },
    [DocumentType.CURP]: {
      id: DocumentType.CURP,
      optionName: intl.formatMessage({
        id: 'curp',
        defaultMessage: 'CURP',
      }),
      regexValidation: new RegExp(/^\d\w{0,17}$/mi),
      isUpperCase: true,
    },
  }

  const getSelectOptions = (intl, list = []) => {
    const searchByDocumentLabel = intl.formatMessage({
      id: 'selectDocument',
      defaultMessage: 'Select document',
    })

    const placeholderOption = {
      id: selectPlaceholderId,
      optionName: searchByDocumentLabel,
      disabled: true,
    }

    const mapOptionIfAny = (result, element) => {
      if (availableOptions[element]) {
        result.push(availableOptions[element])
      }
      return result
    }

    const optionsBasedOnCountry = list.reduce(mapOptionIfAny, [])
    return [placeholderOption, ...optionsBasedOnCountry]
  }

  const selectOptions = getSelectOptions(intl, documents)

  useEffect(() => {
    if (!filters.document) {
      setSelectedDocument(selectPlaceholderId)
      setTypedDocument('')
    }
  }, [filters])

  const onDocumentTypeSelecting = (selectedOption) => {
    if (selectedOption !== selectPlaceholderId) {
      setTypedDocument('')
      setSelectedDocument(selectedOption)
    }
  }

  const applyFilterByDocument = () => {
    if (
      typedDocument
      && length
      && length.maxLength >= typedDocument.length
      && length.minLength <= typedDocument.length
    ) {
      dispatch(
        ProspectTableActions.setFilters({
          document: {
            type: selectedDocument,
            number: typedDocument,
          },
        }),
      )
      setIsSearchPerformed(true)
    }
  }

  const clearFilterByDocument = () => {
    if (isSearchPerformed) {
      dispatch(ProspectTableActions.setFilters({}))
      setIsSearchPerformed(false)
      setTypedDocument('')
    }
  }

  const onChangeTextField = (event) => {
    const selectedOptionRegex = availableOptions[selectedDocument]?.regexValidation
    const regExp = selectedOptionRegex || /e|-|\+|\D/g
    const fieldValue = event.target.value.replace(regExp, '')
    if (!fieldValue.length) {
      clearFilterByDocument()
    }
    const fieldValueResult = availableOptions[selectedDocument]?.isUpperCase
      ? fieldValue.slice(0, length.maxLength).toUpperCase()
      : fieldValue.slice(0, length.maxLength)
    setTypedDocument(fieldValueResult)
  }

  const onEnterKeyPressing = (e) => e.key === 'Enter' && applyFilterByDocument()

  const getTextFieldIcon = () => {
    if (isSearchPerformed) {
      return (
        <Icon
          className={classes.textFieldAdornment}
          width={24}
          height={24}
          name="close"
          onClick={clearFilterByDocument}
        />
      )
    }
    return (
      <Icon
        className={classes.textFieldAdornment}
        width={24}
        height={24}
        name="search"
        onClick={applyFilterByDocument}
      />
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.fieldContainer}>
        <Select
          selectName="select-document-search"
          selectedValue={selectedDocument}
          onChangeEvent={onDocumentTypeSelecting}
          selectOptions={selectOptions}
        />
        {
          isMobile && (
            <Card className={classes.filterWrapper}>
              <FilterWithButton />
            </Card>
          )
        }
      </div>
      <FormControl fullWidth>
        <TextField
          placeholder={inputPlaceHolder}
          value={typedDocument}
          inputProps={length}
          onKeyPress={onEnterKeyPressing}
          onChange={onChangeTextField}
          onIconPress={onEnterKeyPressing}
          icon={getTextFieldIcon()}
          className={classes.textField}
          style={style.textFieldInput}
        />
      </FormControl>
    </div>
  )
}

export default DocumentSearch
