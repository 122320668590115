import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import { getGlobalAccountUrl } from '../../../../services/config'
import Button from '../../../../components/Buttons/Button'
import useStyles from './EditButton.styles'
import { useResponsiveness } from '../../../../hooks/responsive'

// eslint-disable-next-line no-unused-vars
const EditButton = ({ personId }) => {
  const classes = useStyles()
  const { isDesktop } = useResponsiveness()
  const globalExternalService = getGlobalAccountUrl()
  const country = localStorage.getItem('country')?.toLocaleLowerCase()

  const getFormPath = () => `sso?country=${country}&editPersonId=${personId}`

  return (
    <a
      className={classes.anchor}
      href={`${globalExternalService.url}/${getFormPath()}`}
      target="_blank"
      rel="noopener noreferrer"
      aria-label="edit button"
    >
      <Button
        variant="outlined"
        color="primary"
        className={isDesktop ? classes.editButton : ''}
        id="edit-person"
        size="small"
      >
        <FormattedMessage id="edit" defaultMessage="Edit" />
      </Button>
    </a>
  )
}

EditButton.propTypes = {
  personId: PropTypes.string.isRequired,
}

export default EditButton
