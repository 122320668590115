import { CountryId } from '../../../models/country-id'
import { CompanyId } from '../../../models/company-id'
import { TENANT_ID } from '../tenantId'

export const getNaturaMexico = () => ({
  tenantId: TENANT_ID.NATURA_MEXICO,
  country: {
    alpha2Code: CountryId.MEXICO,
    alpha3Code: 'MEX',
    language: 'es',
  },
  company: {
    name: 'NATURA',
    id: CompanyId.NATURA,
    logout: {
      buttonLinkHref: `${process.env.REACT_APP_GLOBAL_ACCOUNT_URL}/logout`,
      externalLink: true,
    },
  },
  header: {
    hasRegisterButtonLink: true,
    registerButtonLinkHref: `${process.env.REACT_APP_GLOBAL_ACCOUNT_URL}/mx/auto`,
    hasLogout: true,
  },
  profile: {
    hasNationalityState: true,
    shouldDisplayThreeNames: true,
    shouldDisplaySendLinkButton: false,
  },
  digitalStore: {
    shouldDisplayRFC: false,
  },
})
