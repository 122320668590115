import { getUserInfo } from './auth'
import { LOCAL_STORAGE_PREFIX } from '../Constants'
import { COUNTRY_TO_LANGUAGE_TAG } from './common'

export const isLocaleDefinedByBrowserLanguage = () => (
  localStorage.getItem(`${LOCAL_STORAGE_PREFIX}hasBrowserLanguage`) === 'true'
)

export const getAppLocale = async () => {
  const userInfo = await getUserInfo()
  if (userInfo?.country) {
    const userInfoCountry = userInfo.country.toLowerCase()
    return COUNTRY_TO_LANGUAGE_TAG[userInfoCountry] ?? userInfoCountry
  }

  if (isLocaleDefinedByBrowserLanguage()) {
    return navigator.language.toLowerCase()
  }

  const localeFromLocalStorage = localStorage.getItem(
    `${LOCAL_STORAGE_PREFIX}language`,
  )
  if (localeFromLocalStorage) {
    return localeFromLocalStorage.toLowerCase()
  }

  return 'en'
}
