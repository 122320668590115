import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => {
  const title = {
    fontWeight: '500',
    flexGrow: 1,
    '@media (min-width: 769px)': {
      paddingLeft: '20px',
      borderLeft: '1px solid #ccc',
    },
  }

  return {
    icon: {
      paddingBottom: 0,
    },
    iconsContainer: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    title,
    rightContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    avatarContainer: {
      marginLeft: theme.spacing(2),
    },
    centeredTitle: {
      ...title,
      textAlign: 'center',
    },
    naturaLogoContainer: {
      marginRight: '20px',
      width: 'auto',
      display: 'flex',
      justifyContent: 'center',
      '@media (min-width: 769px)': {
        width: '200px',
      },
    },
  }
})
