import default_es_nationality_messages from '../es/nationalityMessages'
import default_es_status_messages from '../es/statusMessages'
import default_es_messages from '../es/messages'
import digitalStoreMessages from '../es/digitalStoreMessages'

export default {
  ...default_es_messages,
  ...default_es_status_messages,
  ...default_es_nationality_messages,
  ...digitalStoreMessages,
  countdownMessage: 'Espere {countdown} segundos',
  resendLinkButtonText: 'Enviar Enlace',
  sendingLinkMessage: 'Enlace de reenvío',
  linkSentSuccessfully: 'Enlace enviado con éxito',
  errorMessage: 'Parece que algo no salió como se esperaba. Espere un momento y vuelva a intentarlo.',
  allRegisters: 'Todos los Nombramientos',
  consultantCode: 'Código',
  indicator: 'Recomendadora',
  suggestedForStart: 'En Processo',
  dropouts: 'Rechazado',
  level4: 'Colonia',
  neighborhood: 'Colonia',
  complement: 'Interior/Mz/Lote',
  city: 'Munincipio o Delegación',
  middleName: 'Apellido Paterno',
  lastName: 'Apellido Materno',
  digitalStoreUrl: 'www.avon.com.mx/consultoria/',
  rfc: 'RFC',
}
