import React from 'react'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import { ProgressIndicator } from '@naturacosmeticos/natds-web'
import { FormattedMessage } from 'react-intl'
import useStyles from './PersonalDataCard.styles'

import CustomCard from './Card'
import CustomListItem from './CustomListItem'
import { useTenantConfigs } from '../../../hooks/useTenantConfigs'

const PersonalDataCard = ({ userData }) => {
  const classes = useStyles()
  const { configs, isLoading } = useTenantConfigs()
  const shouldHideThreeNames = !configs?.profile?.shouldDisplayThreeNames
  const fields = [
    {
      fieldId: 'name',
      fieldDefaultMessage: 'Name',
      dataIndex: 'name',
      isHidden: !shouldHideThreeNames,
    },
    {
      fieldId: 'firstName',
      fieldDefaultMessage: 'First Name',
      dataIndex: 'firstName',
      isHidden: shouldHideThreeNames,
    },
    {
      fieldId: 'middleName',
      fieldDefaultMessage: 'Middle Name',
      dataIndex: 'middleName',
      isHidden: shouldHideThreeNames,
    },
    {
      fieldId: 'lastName',
      fieldDefaultMessage: 'Last Name',
      dataIndex: 'lastName',
      isHidden: shouldHideThreeNames,
    },
    {
      fieldId: 'personCode',
      fieldDefaultMessage: 'Person Code',
      dataIndex: 'code',
    },
    {
      fieldId: 'gender',
      fieldDefaultMessage: 'Gender',
      render: () => userData.gender && (
        <FormattedMessage id={`${userData.gender.toLowerCase()}Gender`} />
      ),
    },
    {
      fieldId: 'dateOfBirth',
      fieldDefaultMessage: 'Date of Birth',
      render: () => (userData.birthday ? (
        <span>{userData.birthday}</span>
      ) : (
          ' - '
        )),
    },
    {
      fieldId: 'age',
      fieldDefaultMessage: 'Age',
      render: () => {
        if (!userData.age) return '-'
        return (
          <FormattedMessage
            id="years"
            defaultMessage="{age} years"
            values={{
              age: userData.age,
            }}
          />
        )
      },
    },
  ]

  return (
    <Grid item xs={12} sm={6}>
      <CustomCard
        titleMessageId="personalData"
        titleDefaultMessage="Personal Data"
      >
        {isLoading
          ? (
            <div className={classes.spinner}>
              <ProgressIndicator />
            </div>
)
          : (
            <List dense>
              {fields.map((field) => (
              !field.isHidden
              && (
                <CustomListItem
                  key={field.fieldId}
                  fieldId={field.fieldId}
                  fieldDefaultMessage={field.fieldDefaultMessage}
                  data={field.render ? field.render() : userData[field.dataIndex]}
                />
              )
            ))}
            </List>
)}
      </CustomCard>
    </Grid>
  )
}

export default PersonalDataCard
