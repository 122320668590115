import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  listItem: {
    cursor: 'inherit',
  },
})

const CustomListItem = ({ fieldId, fieldDefaultMessage, data }) => {
  const classes = useStyles()

  return (
    <ListItem
      className={classes.listItem}
      data-testid={`${fieldId}-custom-list-item`}
    >
      <ListItemText
        primary={[
          <strong key="list_item_label">
            <FormattedMessage
              defaultMessage={fieldDefaultMessage}
              id={fieldId}
            />
            :
            {' '}
          </strong>,
          <span data-testid={`${fieldId}-data`} key="list_item_data">
            {data || '-'}
          </span>,
        ]}
      />
    </ListItem>
  )
}

export default CustomListItem
