import {
  put,
  call,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import TYPES from '../types'
import { actions } from './actionCreators'
import { getGroupsService } from '../../services/group'
import { getPersonDetailsService } from '../../services/people'
import { AUTH_ROUTE } from '../../Routes'
import { SUGGESTED_FOR_START_URL } from '../../routes/RestrictedRoutes'

export const EMPTY_USER_DATA = "empty user's details"

function* getGroupsFilter() {
  try {
    const groupsFilter = yield call(getGroupsService)
    yield put(actions.setFilterGroups({ groupsFilter }))
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export function* getUser({ payload }) {
  try {
    const user = yield call(getPersonDetailsService, payload.personId)
    validateUser(user)
    yield put(actions.getUserSuccess(user))
    yield put(push(SUGGESTED_FOR_START_URL))
  } catch (error) {
    yield put(actions.getUserFail(error))
    yield put(push(AUTH_ROUTE))
  }
}

function validateUser(user) {
  if (!user) {
    throw new Error(EMPTY_USER_DATA)
  }
}

export default function* rootSaga() {
  yield takeEvery(TYPES.USER.GET_FILTER_GROUPS, getGroupsFilter)
  yield takeLatest(TYPES.USER.GET_USER_START, getUser)
}
