import React from 'react'

import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { ConnectedRouter } from 'connected-react-router'
import { Helmet } from 'react-helmet'

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import RoutesContainer from './routes/RoutesContainer'
import LocaleProvider from './App.locale'
import NotificationWrapper from './components/Notifications/Notifications'
import { persistor, store, history } from './redux/configureStore'
import Loading from './components/Loading'
import { appMode } from './adapter'
import { ToggleProvider } from './ToggleProvider'
import ThemeWrapper from './ThemeWrapper'
import { DocumentTitle } from './components/DocumentTitle/DocumentTitle'
import { FaviconConfig } from './configs/favicon'

const queryClient = new QueryClient()

function App() {
  const { AuthenticationProvider } = appMode().providers
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <ConnectedRouter history={history}>
          <ToggleProvider>
            <AuthenticationProvider>
              <QueryClientProvider client={queryClient}>
                <ThemeWrapper>
                  <Helmet>
                    <link
                      href="https://fonts.googleapis.com/css?family=Roboto&display=swap"
                      rel="stylesheet"
                    />
                    <link
                      href="https://cdn.jsdelivr.net/npm/@naturacosmeticos/natds-icons@1.0.0-alpha.0/dist/natds-icons.css"
                      rel="stylesheet"
                    />
                  </Helmet>
                  <LocaleProvider>
                    <FaviconConfig />
                    <DocumentTitle />
                    <NotificationWrapper>
                      <RoutesContainer />
                    </NotificationWrapper>
                  </LocaleProvider>
                </ThemeWrapper>
              </QueryClientProvider>
            </AuthenticationProvider>
          </ToggleProvider>
        </ConnectedRouter>
      </PersistGate>
    </ReduxProvider>
  )
}

export default App
