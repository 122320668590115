import { getDefault } from './defaultConfigs'
import { CountryId } from '../../../models/country-id'
import { CompanyId } from '../../../models/company-id'
import { TENANT_ID } from '../tenantId'

export const getNaturaArgentina = () => ({
  ...getDefault(),
  tenantId: TENANT_ID.NATURA_ARGENTINA,
  country: {
    alpha2Code: CountryId.ARGENTINA,
    alpha3Code: 'ARG',
    language: 'es',
  },
  company: {
    name: 'NATURA',
    id: CompanyId.NATURA,
    logout: {
      buttonLinkHref: '/login?country=ARG&company=NATURA',
      externalLink: false,
    },
  },
})
