import React from 'react'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import crypto from 'crypto-js'
import { snakeToCamel } from '../../../helpers/common'
import CustomCard from './Card'
import CustomListItem from './CustomListItem'
import { getGlobalAccountUrl } from '../../../services/config'
import { useTenantConfigs } from '../../../hooks/useTenantConfigs'

const getCustomPhoneListItem = (phones = []) => phones.map(({ number, type = 'contact' }) => (
  <CustomListItem
    key={type}
    fieldId={`${snakeToCamel(type)}Number`}
    fieldDefaultMessage="Contact Number"
    data={number}
  />
))

const ContactCard = ({ userData, personId }) => {
  const { configs } = useTenantConfigs()
  const globalExternalService = getGlobalAccountUrl()
  const fields = [
    {
      fieldId: 'email',
      fieldDefaultMessage: 'E-mail',
      dataIndex: 'email',
    },
  ]

  const handleEditButton = () => {
    const { tenantId, country, company } = configs

    const maintenanceParams = {
      personId,
      tenantId,
      countryId: country.alpha3Code,
      companyId: company.id,
    }

    const token = crypto.AES.encrypt(JSON.stringify(maintenanceParams), window._env_.CONTACT_PASSPHRASE).toString()

    window.location.href = `${globalExternalService.url}/maintenance-contacts?token=${btoa(token)}`
  }

  const showEditButton = () => configs && configs.profile.shouldDisplayContactEditButton && window._env_.SHOW_EDIT_CONTACT_BUTTON === 'true'

  return (
    <Grid item xs={12} sm={6}>
      <CustomCard
        titleMessageId="personalData"
        titleDefaultMessage="Personal Data"
        showEditButton={showEditButton()}
        onEditButtonClick={handleEditButton}
      >
        <List dense>
          {fields.map((field) => (
            <CustomListItem
              key={field.fieldId}
              fieldId={field.fieldId}
              fieldDefaultMessage={field.fieldDefaultMessage}
              data={field.render ? field.render() : userData[field.dataIndex]}
            />
          ))}
          {getCustomPhoneListItem(userData.phones)}
        </List>
      </CustomCard>
    </Grid>
  )
}

export default ContactCard
