import axios from 'axios'
import { getToken } from '../helpers/auth'
import { getConfigsMFEFirebase } from '../helpers/mfeConfisFirebase'
import { BffApiGatewayHeaders } from './configs/request-headers'

export const getRequestCancelSource = () => {
  const { cancel, token } = axios.CancelToken.source()

  return {
    cancelRequests: cancel,
    cancelToken: token,
  }
}

const getAxiosClient = async ({ baseURL, getHeaders, isRecoverSession = false }) => {
  getHeaders = isRecoverSession ? BffApiGatewayHeaders.recoverSession() : BffApiGatewayHeaders.connection()
  const token = getToken()

  const headers = await getHeaders
  const params = {
    baseURL,
    headers,
  }

  if (token) {
    params.headers.Authorization = `Bearer ${token}`
  }

  return axios.create(params)
}

export const getBffApiRecoverSessionConnection = async (getHeaders) => getAxiosClient({
  baseURL: await getConfigsMFEFirebase('REGISTER_REPORTS_BFF_URL') || window._env_.REGISTER_REPORTS_BFF_URL,
  getHeaders,
  isRecoverSession: true,
})

export const getBffApiGatewayConnection = async (getHeaders) => getAxiosClient({
  baseURL: window._env_.REGISTER_REPORTS_BFF_URL,
  getHeaders,
})

export const getBffApiGatewayAuthenticatedConnection = async (getHeaders) => getAxiosClient({
  baseURL: await getConfigsMFEFirebase('REGISTER_REPORTS_BFF_AUTHENTICATED_URL') || window._env_.REGISTER_REPORTS_BFF_AUTHENTICATED_URL,
  getHeaders,
})

export const getGlobalAccountUrl = () => ({
  url: window._env_.REACT_APP_GLOBAL_ACCOUNT_URL,
})
