import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import axios from 'axios'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import { Typography } from '@naturacosmeticos/natds-web'

import { useDispatch, useSelector } from 'react-redux'
import { Icon } from '../Icon'
import IconButton from '../Buttons/IconButton'
import Button from '../Buttons/Button'
import { useStyles } from './Filter.styles'
import FilterByGroup, {
  DefaultFilterByGroupOption,
} from './filterOptions/FilterByGroup'
import { ProspectTableActions } from '../../redux/combinedActions'
import FilterMultipleOptions from './filterOptions/FilterMultipleOptions'
import {
  convertMultipleCheckedOptionsObjectToString,
  convertMultipleCheckedOptionsStringToObject, isObjectNotEmpty,
  statusForFilter,
  subStatusForFilter,
} from '../../models/filterOptions'
import useGlobal from '../../hooks/globalState/useGlobal'
import { getCreditLineFilterOptions } from './filterOptions/FilterByCreditLine'
import { getSubStatusService } from '../../services/status'
import { getRequestCancelSource } from '../../services/config'
import { useToggle } from '../../hooks/useToggle'

const getAvailableSubStatusOptions = (SHOW_DIGITAL_STORE_INFO, options) => (SHOW_DIGITAL_STORE_INFO === true
  ? options : options.filter(({ id, name }) => id !== 73 && name !== 'PENDING_DIGITAL_SPACE'))

export default ({ open, onClose }) => {
  const [selectedGroup, setSelectedGroup] = useState(
    DefaultFilterByGroupOption,
  )
  const [checkedStatus, setCheckedStatus] = useState({})
  const [checkedSubStatus, setCheckedSubStatus] = useState({})
  const [subStatus, setSubStatus] = useState({ loading: true })
  const [checkedCreditLine, setCheckedCreditLine] = useState({})

  const { SHOW_DIGITAL_STORE_INFO } = useToggle()
  const dispatch = useDispatch()
  const { filters } = useSelector(({ prospectTable }) => prospectTable)

  const [globalState] = useGlobal()

  const { showStatus } = globalState.filters

  const intl = useIntl()
  const creditLineOptions = getCreditLineFilterOptions(intl)
  const statusOptions = statusForFilter(intl)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (globalState.filters.prospectStatusScreen) {
      const { cancelRequests, cancelToken } = getRequestCancelSource()
      setSubStatus({ loading: true })
      if (open) {
        setCheckedSubStatus({})
      }
      const query = convertMultipleCheckedOptionsObjectToString(checkedStatus)
      getSubStatusService(
        globalState.filters.prospectStatusScreen,
        query,
        cancelToken,
      )
        .then((options) => {
          const availableSubStatusOptions = getAvailableSubStatusOptions(SHOW_DIGITAL_STORE_INFO, options)

          setSubStatus({
            loading: false,
            options: subStatusForFilter(intl, availableSubStatusOptions),
          })
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubStatus({ loading: false, options: [] })
          }
        })
      return cancelRequests
    }
    // eslint-disable-next-line
  }, [globalState.filters.prospectStatusScreen, checkedStatus, intl, SHOW_DIGITAL_STORE_INFO])

  useEffect(() => {
    if (filters.group === undefined) {
      setSelectedGroup(DefaultFilterByGroupOption)
    }
    if (filters.subStatus === undefined) {
      setCheckedSubStatus({})
    }
    if (filters.creditLine === undefined) {
      setCheckedCreditLine({})
    }
  }, [filters])

  const getStatusFilter = () => convertMultipleCheckedOptionsObjectToString(checkedStatus)

  const getSubStatusFilter = () => convertMultipleCheckedOptionsObjectToString(checkedSubStatus)

  const getCreditLineFilterIfAny = () => {
    const filterParam = convertMultipleCheckedOptionsObjectToString(
      checkedCreditLine,
    )
    return filterParam ? { creditLine: filterParam } : {}
  }

  const setFilters = () => {
    const chosenFilters = {
      group: selectedGroup,
      subStatus: getSubStatusFilter(),
      status: getStatusFilter(),
      ...getCreditLineFilterIfAny(),
    }

    dispatch(ProspectTableActions.setFilters(chosenFilters))
    onClose()
  }

  const {
    groupsFilter: { canFilterByGroup, groups },
  } = useSelector(({ user }) => user)

  const resetCheckedStateWithGlobal = (globalValue, localValue, setStateCallback) => {
    if (globalValue) {
      setStateCallback(convertMultipleCheckedOptionsStringToObject(globalValue))
    } else if (isObjectNotEmpty(localValue)) {
      setStateCallback({})
    }
  }

  const handleOnClose = () => {
    onClose()
    if (filters.group) {
      setSelectedGroup(filters.group)
    } else if (selectedGroup.name) {
      setSelectedGroup(DefaultFilterByGroupOption)
    }
    resetCheckedStateWithGlobal(filters.creditLine, checkedCreditLine, setCheckedCreditLine)
    resetCheckedStateWithGlobal(filters.status, checkedStatus, setCheckedStatus)
    resetCheckedStateWithGlobal(filters.subStatus, checkedSubStatus, setCheckedSubStatus)
  }

  const classes = useStyles()
  return (
    <Drawer
      open={open}
      onClose={handleOnClose}
      anchor="right"
      PaperProps={{ elevation: 4 }}
      classes={{ paper: classes.paper }}
      className={classes.drawer}
    >
      <div className={classes.drawerHeader}>
        <Typography variant="body2">
          <FormattedMessage id="filters" defaultMessage="Filters" />
        </Typography>
        <IconButton aria-label="Close" onClick={handleOnClose} id="close-filter">
          <Icon width={24} height={24} name="close" />
        </IconButton>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.drawerContent}>
        <FilterByGroup
          groups={groups}
          canFilterByGroup={canFilterByGroup}
          selectedGroup={selectedGroup}
          onSelectGroup={setSelectedGroup}
        />
        <FilterMultipleOptions
          title={(
            <FormattedMessage
              id="filterByCreditLine"
              defaultMessage="Credit Line"
            />
          )}
          options={creditLineOptions}
          checkedOptions={checkedCreditLine}
          handleCheck={setCheckedCreditLine}
        />
        {showStatus && (
          <FilterMultipleOptions
            title={
              <FormattedMessage id="status" defaultMessage="Status" />
            }
            options={statusOptions}
            checkedOptions={checkedStatus}
            handleCheck={setCheckedStatus}
          />
        )}
        <FilterMultipleOptions
          title={(
            <FormattedMessage
              id="signUpStatus"
              defaultMessage="Registration status"
            />
          )}
          loading={subStatus.loading}
          options={subStatus.options}
          checkedOptions={checkedSubStatus}
          handleCheck={setCheckedSubStatus}
        />
      </div>
      <div className={classes.drawerFooter}>
        <Button
          onClick={setFilters}
          variant="contained"
          color="primary"
          fullWidth
        >
          <FormattedMessage id="applyFilter" defaultMessage="Filter" />
        </Button>
      </div>
    </Drawer>
  )
}
