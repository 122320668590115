import { CountryId } from '../../../models/country-id'
import { CompanyId } from '../../../models/company-id'
import { TENANT_ID } from '../tenantId'

export const getAvonArgentina = () => ({
  tenantId: TENANT_ID.AVON_ARGENTINA,
  country: {
    alpha2Code: CountryId.ARGENTINA,
    alpha3Code: 'ARG',
    language: 'es',
  },
  company: {
    name: 'AVON',
    id: CompanyId.AVON,
    logout: {
      buttonLinkHref: `${process.env.REACT_APP_GLOBAL_ACCOUNT_URL}/logout`,
      externalLink: true,
    },
  },
  header: {
    hasRegisterButtonLink: false,
    /**
     * Verificar sobre esse path
     */
    registerButtonLinkHref: `${process.env.REACT_APP_GLOBAL_ACCOUNT_URL}/ar/auto`,
    hasLogout: true,
  },
  profile: {
    hasNationalityState: true,
    shouldDisplayThreeNames: true,
    shouldDisplaySendLinkButton: false,
  },
  digitalStore: {
    shouldDisplayRFC: false,
  },
})
