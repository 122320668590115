export const TENANT_ID = {
  NATURA_BRAZIL: 'ca8ffb10-6ef1-11ec-8f99-73f1883b8292',
  NATURA_COLOMBIA: '8c776e6f-d2f7-4f58-b996-16120555ceb1',
  NATURA_PERU: '64d0a430-e367-42f3-8e9b-6eb1ae60d7bc',
  NATURA_MALAYSIA: 'fd11f2e7-b101-4e7e-9a92-0b0a76c05264',
  NATURA_CHILE: '51326ed9-1db3-4af0-bf3e-40190a68e98f',
  NATURA_ARGENTINA: '8374a616-8b1e-46af-8d41-2bcf683234db',
  AVON_MEXICO: '3c9c51b3-0110-4b95-b5de-ca31a01b585c',
  AVON_BRAZIL: '9af7616d-656c-4a59-8af3-0413615f6dd3',
  NATURA_MEXICO: '4cb704d1-0122-4b88-a7a6-9eef40741416',
  AVON_ARGENTINA: 'b08cf0f8-1656-412e-a9ce-5d47db8699e5',
}
