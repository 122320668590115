import React from 'react'
import PropTypes from 'prop-types'
import Keycloak from 'keycloak-js'
import { KeycloakProvider } from '@react-keycloak/web'

import { KEYCLOAK_CONFIG, KEYCLOAK_INIT_CONFIG } from './Constants'
// eslint-disable-next-line import/no-cycle
import {
  updateUserInfo,
  removeUserInfo,
  getUserInfoByKeycloak,
  STORAGE_COUNTRY_KEY,
} from './helpers/auth'
import { parseJwt } from './helpers/common'

export const keycloak = new Keycloak(KEYCLOAK_CONFIG)

export const onEvent = async (event, error) => {
  if (error) return

  switch (event) {
    case 'onAuthSuccess':
    case 'onAuthRefreshSuccess': {
      const userInfo = await getUserInfoByKeycloak()
      updateUserInfo(userInfo)
    }
      break
    case 'onAuthLogout':
    case 'onAuthRefreshError':
      removeUserInfo()
      break
    default:
      removeUserInfo()
      break
  }
}

const onTokens = (data) => {
  const { country } = parseJwt(data.token)
  localStorage.setItem(STORAGE_COUNTRY_KEY, country)
}

function KeycloakWrapper({ children, keycloak }) {
  return (
    <KeycloakProvider
      LoadingComponent={<div data-testid="required-div" />}
      keycloak={keycloak}
      onEvent={onEvent}
      initConfig={KEYCLOAK_INIT_CONFIG}
      onTokens={onTokens}
    >
      {children}
    </KeycloakProvider>
  )
}

KeycloakWrapper.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  keycloak: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types,react/require-default-props
  user: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  onAuthenticate: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  onAuthenticationRefresh: PropTypes.func,
}

KeycloakWrapper.defaultProps = {
  keycloak,
}

export default KeycloakWrapper
