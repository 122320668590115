export const getDefault = () => ({
  header: {
    hasRegisterButtonLink: false,
    registerButtonLinkHref: null,
    hasLogout: false,
  },
  profile: {
    hasNationalityState: false,
    shouldDisplayThreeNames: false,
    shouldDisplaySendLinkButton: false,
    shouldDisplayContactEditButton: false,
  },
  digitalStore: {
    shouldDisplayRFC: false,
  },
})
