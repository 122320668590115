import { getBffApiRecoverSessionConnection } from './config'
import { CountryId } from '../models/country-id'
import { CompanyId } from '../models/company-id';

export const recoverSession = async (ssoToken) => {
  const connection = await getBffApiRecoverSessionConnection()
  const country = localStorage.getItem('country')
  const company = localStorage.getItem('company')
  let route = '/accounts/register-reports/v1/recover-session'

  if(country?.toLocaleLowerCase() === CountryId.BRAZIL.toLocaleLowerCase() && Number(company) === CompanyId.AVON){
    route += '-avon-brazil'
  }

  const result = await connection.post(route, {
    token: ssoToken,
  })
  return result
}
