import React from 'react'
import Helmet from 'react-helmet'
import { useTenantConfigs } from '../../hooks/useTenantConfigs'
import { CompanyId, CompanyName } from '../../models'

export const FaviconConfig = () => {
  const { configs } = useTenantConfigs()

  const companyId = configs?.company?.id

  const isProduction = process.env.NODE_ENV === 'production'
  const companyName = getCompanyNameFormattedToLowerCase({ companyId })
  const faviconHref = getFaviconHref({ isProduction, companyName })

  return (
    <>
      {faviconHref && (
        <Helmet>
          <link rel="shortcut icon" id="favicon" href={faviconHref} />
        </Helmet>
      )}
    </>
  )
}

function getCompanyNameFormattedToLowerCase({ companyId }) {
  if (companyId === CompanyId.NATURA) {
    return CompanyName.NATURA.toLowerCase()
  }

  if (companyId === CompanyId.AVON) {
    return CompanyName.AVON.toLowerCase()
  }

  return ''
}

function getFaviconHref({ isProduction, companyName }) {
  const href = isProduction
    ? `/icons/${companyName}/favicon.ico`
    : `/public/icons/${companyName}/favicon.ico`

  return href
}
