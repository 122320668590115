import React from 'react'
import { FormattedMessage } from 'react-intl'

import PersonalDataCell from '../../components/PersonalDataCell/PersonalDataCell'
import { snakeToCamel } from '../../helpers/common'
import ContactCell from '../../components/ContactCell/ContactCell'

export const mobileColumns = [
  {
    dataIndex: 'name',
    labelDefaultMessage: 'Personal Data',
    labelId: 'personalData',
    ordered: true,
    render: (record) => <PersonalDataCell {...record} />,
    width: '60%',
  },
  {
    dataIndex: 'createdRoleAt',
    labelDefaultMessage: 'Register On',
    labelId: 'registerOn',
    ordered: true,
    width: '40%',
  },
]

export const desktopColumns = [
  {
    dataIndex: 'name',
    labelDefaultMessage: 'Personal Data',
    labelId: 'personalData',
    ordered: true,
    render: (record) => <PersonalDataCell {...record} />,
    width: '30%',
  },
  {
    dataIndex: 'createdRoleAt',
    labelDefaultMessage: 'Register On',
    labelId: 'registerOn',
    ordered: true,
    width: '10%',
  },
  {
    dataIndex: 'candidateId',
    labelDefaultMessage: 'CN Code',
    labelId: 'consultantCode',
    width: '10%',
  },
  {
    dataIndex: 'substatus',
    labelDefaultMessage: 'Register Status',
    labelId: 'registerStatus',
    width: '20%',
    render: ({ substatus }) => (substatus ? (
      <FormattedMessage id={snakeToCamel(`sub_status_${substatus}`)} />
      ) : (
        ' - '
      )),
  },
  {
    dataIndex: 'contact',
    labelDefaultMessage: 'Contact',
    labelId: 'contact',
    width: '30%',
    render: (record) => <ContactCell {...record} />,
  },
]
