import { makeStyles } from '@naturacosmeticos/natds-web'

export const useStyles = makeStyles((theme) => ({
  gridContainer: {
    margin: 10,
    '@media (min-width: 769px)': {
      marginTop: `${theme.spacing(0)}px`,
    },
  },
  appBar: { margin: '0 14px 14px 14px' },
  prospectToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  prospectName: {
    ...theme.typography.body1,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  propectButton: {
    marginLeft: `${theme.spacing(1)}px`,
  },
}))
