import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Chip } from '@naturacosmeticos/natds-web'

import Button from '../../../components/Buttons/Button'

export const PeopleTableContactMadeButton = ({
  isLoading, onClick, buttonText, ButtonProps, ChipProps,
}) => (
  <Button
    disabled={isLoading}
    className="madeContactButton"
    onClick={onClick}
    classes={ButtonProps?.classes}
    color="primary"
    variant="contained"
  >
    {isLoading ? (
      <CircularProgress size={20} color="inherit" />
      ) : (
        <>
          {buttonText}
          <Chip
            size="small"
            classes={ChipProps?.classes}
            color="default"
            label={ChipProps?.label}
            {...ChipProps}
          />
        </>
        )}
  </Button>
  )

PeopleTableContactMadeButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  ButtonProps: PropTypes.objectOf(PropTypes.any),
  ChipProps: PropTypes.objectOf(PropTypes.any),
}

PeopleTableContactMadeButton.defaultProps = {
  ButtonProps: {},
  ChipProps: {},
}
