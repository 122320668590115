import messages_pt from './pt/messages'
import messages_es from './es/messages'
import messages_en from './en/messages'
import messages_es_CO from './es-co/messages'
import messages_es_AR from './es-ar/messages'
import messages_es_MX from './es-mx/messages'

const BROWSER_BR_KEY = 'pt-br'
const BROWSER_US_KEY = 'en-us'
const BROWSER_MY_KEY = 'en-my'
const BROWSER_AR_KEY = 'es-ar'
const BROWSER_CL_KEY = 'es-cl'
const BROWSER_CO_KEY = 'es-co'
const BROWSER_PE_KEY = 'es-pe'
const BROWSER_MX_KEY = 'es-mx'

const messages = {
  pt: messages_pt,
  br: messages_pt,
  [BROWSER_BR_KEY]: messages_pt,

  en: messages_en,
  [BROWSER_US_KEY]: messages_en,
  my: messages_en,
  [BROWSER_MY_KEY]: messages_en,

  es: messages_es,
  pe: messages_es,
  [BROWSER_PE_KEY]: messages_es,
  cl: messages_es,
  [BROWSER_CL_KEY]: messages_es,
  co: messages_es_CO,
  [BROWSER_CO_KEY]: messages_es_CO,
  ar: messages_es_AR,
  [BROWSER_AR_KEY]: messages_es_AR,
  mx: messages_es_MX,
  [BROWSER_MX_KEY]: messages_es_MX,
}

export default messages
