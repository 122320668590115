import React, { useEffect, useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { useTenantConfigs } from '../../hooks/useTenantConfigs'
import { CompanyId } from '../../models/company-id'

export const DocumentTitle = () => {
    const [messageId, setMessageId] = useState('naturaReports')
    const { isLoading, configs } = useTenantConfigs()

    useEffect(() => {
      if (!isLoading && configs?.company.id === CompanyId.AVON) {
        setMessageId('avonReports')
      }
    }, [isLoading])

    return (
      <FormattedMessage id={messageId} defaultMessage="Reports">
        {(message) => {
        if (document.title !== message) {
          document.title = message
        }
        return null
      }}
      </FormattedMessage>
    )
  }
