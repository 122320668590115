import React, { useState } from 'react'
import {
  Avatar as MuiAvatar,
  Button,
  Card,
  CircularProgress,
  ClickAwayListener,
  Typography,
  Box,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useQuery } from 'react-query'
import { useStyles } from './Avatar.styles'
import { getUserInfo } from '../../helpers/auth'
import { fetchAvatarInfo } from '../../services/avatar'

const fetchInformation = async () => {
  const { personId } = await getUserInfo()
  const avatarInfo = await fetchAvatarInfo({ personId })
  return {
    personId,
    ...avatarInfo,
  }
}

export const Avatar = () => {
  const classes = useStyles()

  const { isLoading, data } = useQuery('avatarInfo', fetchInformation)
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress aria-label="loading" />
      </div>
    )
  }

  const AvatarModal = () => {
    const history = useHistory()
    const isConsultant = data.roles?.some((role) => (role.roleId === 1))
    const digitalStoreURL = process.env.REACT_APP_DIGITAL_STORE_STATUS_AVON_MX_URL
    const activeButtonForDigitalStoreStatus = process.env.REACT_APP_DIGITAL_STORE_STATUS_AVON_MX_BUTTON
    return (
      <ClickAwayListener onClickAway={() => setIsModalOpen(false)}>
        <Card className={classes.userCard}>
          <Typography
            variant="body1"
            color="secondary"
          >
            {data?.name || '-'}
          </Typography>
          <Typography
            variant="caption"
          >
            <FormattedMessage id="sellerId" />
            {': '}
            {data?.sellerId || '-'}
          </Typography>
          <div>
            {isConsultant && activeButtonForDigitalStoreStatus ? (
              <Box mt={1} mb={1}>
                <Button
                  className={classes.buttonStyle}
                  color="primary"
                  variant="outlined"
                  aria-label="digital store status"
                  href={digitalStoreURL}
                >
                  <FormattedMessage id="ownDigitalStore" />
                </Button>
              </Box>
            ) : null }
            <Button
              className={classes.buttonStyle}
              color="primary"
              variant="outlined"
              aria-label="logout"
              onClick={() => history.push('/logout')}
            >
              <FormattedMessage id="logout" />
            </Button>
          </div>
        </Card>
      </ClickAwayListener>
    )
  }

  return (
    <>
      <MuiAvatar data-testid="avatarIcon" className={classes.avatarStyle} onClick={() => setIsModalOpen(!isModalOpen)}>
        {data?.initials}
      </MuiAvatar>
      {isModalOpen && <AvatarModal />}
    </>
  )
}
