import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@naturacosmeticos/natds-web'

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  cardTitle: {
    fontWeight: 'bold',
  },
  cardHeaderContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})

const CustomCard = ({
                      titleDefaultMessage, titleMessageId, children, showEditButton, onEditButtonClick,
                    }) => {
  const classes = useStyles()
  return (
    <Card className={classes.root} data-testid={titleMessageId}>
      <div className={classes.cardHeaderContent}>
        <CardHeader
          title={(
            <Typography classes={{ root: classes.cardTitle }} variant="body1">
              <FormattedMessage
                defaultMessage={titleDefaultMessage}
                id={titleMessageId}
              />
            </Typography>
          )}
        />
        {
          showEditButton
          && (
            <Button color="primary" onClick={onEditButtonClick}>
              <FormattedMessage id="editButton" defaultMessage="Edit" />
            </Button>
          )
        }
      </div>
      {children}
    </Card>
  )
}

export default CustomCard
