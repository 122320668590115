export default {
  subStatusActiveCnPendingCsc: 'Função CBN ativa',
  subStatusActiveDebt: 'Rejeitado por dívida',
  subStatusActiveSeller: 'Consultora Ativa',
  subStatusAddressIncomplete: 'Endereço incompleto',
  subStatusAddressValidationFail: 'Endereço Inválido',
  subStatusAllocationStructureError: 'Erro para alocar em estrutura comercial',
  subStatusApprovedByGr: 'Aprovada por GR',
  subStatusApprovedByGv: 'Aprovada por GV',
  subStatusApprovedSeller: 'Aprovada',
  subStatusAttachmentMissing: 'Falta envio de documentos',
  subStatusAttachmentValidationFail: 'Erro na validação de documentos',
  subStatusAttachmentValidationSuccess: 'Validação de documentos com sucesso',
  subStatusBrmBadParameters: 'Parâmetros BRM incorretos',
  subStatusCeased: 'Finalizado',
  subStatusCepValidationFail: 'Código Postal não encontrado',
  subStatusClearSaleValidationFail: 'Serviço indisponível',
  subStatusCnActive: 'Papel CBN ativo',
  subStatusCreatingPerson: 'Falha na criação do candidato',
  subStatusCreditRatingError: 'Registro em análise de crédito',
  subStatusCreditValidation: 'Rejeitado por políticas Natura',
  subStatusDocumentPosted: 'Documento registrado',
  subStatusEmailExistent: 'E-mail existente',
  subStatusErrorCompleteDataOnBrm: 'Registro em processo no BRM',
  subStatusEstablishmentNotFound: 'Estabelecimento não encontrado',
  subStatusExistentEmailOnGera: 'E-mail existente no GERA',
  subStatusExistentRole: 'Papel já existe',
  subStatusFailureCeaseGera: 'Erro para cessar no GERA',
  subStatusFinalDataValidation: 'Validação final de dados',
  subStatusGeraDebtServiceError: 'Erro ao verificar débitos',
  subStatusIntegrationErrorWithGera: 'Registro em processo no GERA',
  subStatusManuallyApproved: 'Aprovado manualmente',
  subStatusNameValidation: 'Restrição nome',
  subStatusOpenTitle: 'Reprovado em Títulos',
  subStatusPendingAddressBlocklistReview: 'Pendente de análise para blocklist',
  subStatusPendingAddressReview: 'Pendente análise: Endereço',
  subStatusPendingAllocate: 'Alocação pendente',
  subStatusPendingApproval: 'Aprovação pendente',
  subStatusPendingCommercialStructureNotCovered:
    'Estrutura comercial não encontrada',
  subStatusPendingCreditAnalysis: 'Análise de crédito pendente',
  subStatusPendingCscApproval: 'Pendente aprovação CSC',
  subStatusPendingData: 'Dados pendentes',
  subStatusPendingDigitalSpace: 'Espaço digital pendente',
  subStatusPendingDocumentation: 'Documentos pendentes',
  subStatusPendingEmailVerification: 'Validação de contatos pendente',
  subStatusPendingGrApproval: 'Pendente aprovação GR',
  subStatusPendingGvApproval: 'Pendente aprovação GV',
  subStatusPendingManualAssignment: 'Atribuição manual pendente',
  subStatusPendingPrepaid: 'Pré-pago pendente',
  subStatusPendingRegistrationApproval: 'Pendente análise de cadastro',
  subStatusPendingRestartReview: 'Pendente análise Reinício',
  subStatusProcessing: 'Em análise pela Natura',
  subStatusQuarantine: 'Quarentena',
  subStatusRejectedByBlocklist: 'Rejeitado pela blocklist',
  subStatusRejectedByCsc: 'Rejeitado por CSC',
  subStatusRejectedByGr: 'Rejeitado pela GR',
  subStatusRejectedByGv: 'Rejeitado pela GV',
  subStatusRejectedByRegisterTeam: 'Rejeitado pela Equipe de Cadastro',
  subStatusRejectedByMinimalPeriodAsCeased: 'Rejeitado por período mínimo como cessado',
  subStatusRejectedWithReason: 'Rejeitado com motivo',
  subStatusRenunciationCandidate: 'Desistência Candidata',
  subStatusRenunciationNatura: 'Desistência Natura',
  subStatusRestrictedAddress: 'Endereço sem cobertura',
  subStatusRestrictedRole: 'Restrição Papel',
  subStatusRestrictionEmail: 'Restrição email',
  subStatusRestrictionTelephone: 'Restrição telefone',
  subStatusRetrieveCycleError: 'Erro ao recuperar o ciclo',
  subStatusSystemFail: 'Falha do sistema',
  subStatusTermOfAcceptance: 'Termos de aceitação',
  subStatusWithoutAddress: 'Sem endereço',
  subStatusWorkflowExecutionError: 'Erro de execução do workflow',
  subStatusAgreementsServiceError: 'Erro ao aceitar os termos',
  subStatusInvalidAddresses: 'Endereços inválidos',
  subStatusInvalidDeliveryAddress: 'Endereço de entrega inválido',
  subStatusInvalidMailingAddress: 'Endereço residencial inválido',
  subStatusMissingConfirmationCode: 'Autorização dos termos e condições pendente',
  subStatusSitidataServiceError: 'Erro Sitidata',
  subStatusPendingDigitalSignature: 'Pendente assinatura digital',
}
