import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import prospectTable from './prospectTable/reducers'
import auth from './auth/reducers'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  user,
  prospectTable,
  auth,
})

export default createRootReducer
