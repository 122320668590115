import substatusMessages from './substatusMessages'
import nationalityMessages from './nationalityMessages'
import statusMessages from './statusMessages'

export default {
  admissionDate: 'Admission Date',
  age: 'Age',
  allRegisters: 'All registers',
  alreadyGotInTouch: 'Already Got In Touch',
  alreadyMadeContact: 'Already Made Contact',
  alternativeAddress: 'Alternative Address',
  applyFilter: 'Filter',
  approve: 'Approve',
  approved: 'Approved',
  approveRegistrationDialogTitle: 'Approve Registration',
  approveRegistrationDialogMessage:
    'Are you sure you want to approve the registration of <strong>{name}</strong>?',
  attachments: 'Attachments',
  attachmentsAddress: 'Proof of address',
  attachmentsCelulaCiudadaniaBack: '{doc} - Back',
  attachmentsCelulaCiudadaniaFront: '{doc} - Front',
  attachmentsCelulaExtranjeriaBack: '{doc} - Back',
  attachmentsCelulaExtranjeriaFront: '{doc} - Front',
  attachmentsDocumentBack: '{doc} - Back',
  attachmentsDocumentForeignBack: '{doc} - Back',
  attachmentsDocumentForeignFront: '{doc} - Front',
  attachmentsDocumentFront: '{doc} - Front',
  attachmentsIntention: 'Photo of the intention',
  attachmentsProofEconomicLife: 'Proof Economic Life',
  attachmentsTerms: 'Terms and conditions',
  beginnings: 'Beginnings',
  birthday: 'Birthday',
  callToPerson: 'Call {name}',
  cancel: 'Cancel',
  cellphone: 'Cellphone',
  city: 'City',
  citizenCard: 'Citizen Card',
  cpf: 'CPF',
  cologne: 'Cologne',
  commercialStructure: 'Commercial Structure',
  complement: 'Complement',
  consultantCode: 'BC code',
  consultantRegistration: 'Consultant Registration',
  contact: 'Contact',
  contactNumber: 'Contact Number',
  country: 'Country',
  curp: 'CURP',
  credit: 'Credit',
  creditLinePostPaid: 'Credit',
  dateOfBirth: 'Date of Birth',
  decideLater: 'Decide Later',
  deliveryAddress: 'Delivery Address',
  department: 'Department',
  dropouts: 'Disapproved/Desistance',
  district: 'District',
  dniArgentina: 'DNI',
  documentNumber: 'Document No.',
  document: 'Document',
  documents: 'Documents',
  dni: 'DNI',
  edit: 'Edit registration',
  editButton: 'Edit',
  email: 'E-mail',
  errorTitle: 'Sorry, we have a problem in our system…',
  errorSubtitle: 'Please try again later.',
  establishment: 'Establishment',
  filterByCreditLine: 'Credit line',
  status: 'Status',
  filters: 'Filters',
  femaleGender: 'Female',
  foreignCard: 'Foreign Card',
  foreignRegistry: 'Foreign Registry',
  gender: 'Gender',
  group: 'Group',
  helloStr: 'Hello! This is the Reports APP',
  homeTime: 'Home Time',
  indicator: 'Referral',
  leader: 'Leader',
  lineNumber: 'Line Number',
  logout: 'Logout',
  mailingAddress: 'Mailing Address',
  maleGender: 'Male',
  mobileNumber: 'Mobile Number',
  moreDetails: 'More details',
  moveToNewRegisters: 'Move To New Registers',
  myRegisters: 'My Registers',
  myCad: 'MyCad',
  name: 'Name',
  nationalRegistry: 'National Registry',
  nationality: 'Nationality',
  naturaBrand: "Image with Natura's Brand",
  avonBrand: "Image with Avon's Brand",
  naturaReports: 'Natura Reports',
  avonReports: 'Avon Reports',
  neighborhood: 'Neighborhood',
  newRegisters: 'New Registers',
  no: 'no',
  noNewRegisters: 'There are no new registers',
  noRegisters: 'There are no registers',
  noRegisteredAddress: 'There is no registered address',
  noRegistersContacted: 'There are no already contacted registers',
  noResultsOnCommercialStructure:
    'No results found on your commercial structure',
  number: 'Number',
  origin: 'Origin',
  otherGender: 'Other',
  paymentPlan: 'Payment Plan',
  passport: 'Passport',
  passportMalasya: 'Passport',
  personCode: 'Person Code',
  sellerId: 'Code',
  personalData: 'Personal Data',
  prePaid: 'Pre Paid',
  province: 'Province',
  references: 'References',
  region: 'Region',
  registerOn: 'Register On',
  registerReports: 'Register reports',
  registerStatus: 'Registration Status',
  registrant: 'Registrant',
  registrationDenied: 'Registration Denied',
  registerAllocatedOnCommercialStructure:
    'Register allocated on commercial structure: ',
  registerButtonLinkLabel: 'New Register',
  registerOriginGlobal: 'Global Register',
  registerOriginMinegocio: 'MiNegocio',
  registerOriginUndefined: 'Undefined',
  reject: 'Reject',
  rejectRegistration: 'Reject Registration',
  rejectRegistrationDialogTitle: 'Reject Registration',
  rejectRegistrationDialogMessage:
    'Are you sure you want to reject the registration of <strong>{name}</strong>?',
  rejectionReason: 'Rejection Reason',
  restart: 'Restart',
  resultsOutsideStructure: 'Results outside the commercial structure',
  rg: 'RG',
  rutChile: 'RUT',
  searchByDocument: 'Search by document',
  selectDocument: 'Select document',
  selectGroup: 'Select Group',
  selfRegistration: 'Self Register',
  signUpStatus: 'Registration status',
  state: 'State',
  street: 'Street',
  streetAddressLine1: 'Street Address Line 1',
  streetAddressLine2: 'Street Address Line 2',
  suggestedForStart: 'Suggested for start',
  termsAndConditionsRemark: 'Terms and Condition (remark)',
  unknownDocumentType: 'Document',
  waitingFirstOrder: 'New Recruits',
  years: '{age} years',
  yes: 'yes',
  yesImSure: "Yes, I'm sure",
  zipCode: 'Zip Code',
  zipcode: 'Zip Code', // this key came from BFF without camelCase
  reprocessButtonLabel: 'Reprocess',
  reprocessDialogTitle: 'Are you sure you want to reprocess?',
  reprocessDialogText: 'This will reprocess the registration, are you sure you want to do that?',
  reprocessDialogBack: 'Cancel',
  reprocessDialogConfirm: 'Yes, I\'m sure',
  ineMexico: 'INE',
  stateBirth: 'State Birth',
  ...statusMessages,
  ...substatusMessages,
  ...nationalityMessages,
}
