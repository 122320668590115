import React from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { FormattedMessage, useIntl } from 'react-intl'
import IconButton from '../Buttons/IconButton'
import { useStyles } from './Header.styles'
import { useResponsiveness } from '../../hooks/responsive'
import naturaBrand from '../../assets/img/naturaBrand.svg'
import avonBrand from '../../assets/img/avonBrand.svg'
import useCompany from '../../hooks/useCompany'
import { CompanyId } from '../../models/company-id'
import { useTenantConfigs } from '../../hooks/useTenantConfigs'
import { Avatar } from '../Avatar/Avatar'

const NewRegisterButtonLink = ({ href }) => (
  <Button
    href={href}
    variant="contained"
    color="primary"
    aria-label="New Register"
  >
    <FormattedMessage id="registerButtonLinkLabel" />
  </Button>
)

const Header = ({
  children,
  title,
  hasBackButton,
  hideNewRegisterButton,
  hasElevation,
}) => {
  const history = useHistory()
  const classes = useStyles()
  const { isMobile, isDesktop } = useResponsiveness()
  const { isLoading, configs } = useTenantConfigs()

  const shouldRenderNewRegisterButton = !hideNewRegisterButton
    && !isLoading
    && configs?.header.hasRegisterButtonLink

  const shouldRenderTitle = isDesktop || (!hideNewRegisterButton && !shouldRenderNewRegisterButton)

  const shouldRenderLogo = isDesktop || (!shouldRenderTitle && !shouldRenderNewRegisterButton)

  const shouldRenderAvatar = configs?.header.hasLogout && !hideNewRegisterButton

  return (
    <AppBar
      position={isDesktop ? 'fixed' : 'static'}
      color="inherit"
      className={classes.appBar}
      elevation={hasElevation || isDesktop ? 4 : 0}
    >
      <Toolbar classes={{ root: classes.toolbar }}>
        {hasBackButton && isMobile && (
          <IconButton
            edge="start"
            aria-label="back"
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        {
          shouldRenderLogo && (<Logo />)
        }
        {
          shouldRenderTitle && (
            <>
              <Typography
                color="textPrimary"
                variant="h6"
                noWrap
                classes={{
                  root: classes.title,
                }}
              >
                {title}
              </Typography>
            </>
          )
        }
        <div className={classes.rightContainer}>
          {shouldRenderNewRegisterButton && (
            <NewRegisterButtonLink href={configs.header.registerButtonLinkHref} />
          )}
          {shouldRenderAvatar && (
            <div className={classes.avatarContainer}>
              <Avatar />
            </div>
          )}
        </div>
        {children}
      </Toolbar>
    </AppBar>
  )
}

const Logo = () => {
  const classes = useStyles()
  const intl = useIntl()
  const { isCurrentCompany } = useCompany()

  return (
    <>
      {isCurrentCompany(CompanyId.NATURA) && (
        <div className={classes.naturaLogoContainer}>
          <Link to="/">
            <img
              alt={intl.formatMessage({
                id: 'naturaBrand',
                defaultMessage: 'Natura Brand',
              })}
              width={150}
              src={naturaBrand}
            />
          </Link>
        </div>
      )}
      {isCurrentCompany(CompanyId.AVON) && (
        <div className={classes.naturaLogoContainer}>
          <Link to="/">
            <img
              alt={intl.formatMessage({
                id: 'avonBrand',
                defaultMessage: 'Avon Brand',
              })}
              width={150}
              src={avonBrand}
            />
          </Link>
        </div>
      )}
    </>
  )
}

Header.defaultProps = {
  hasBackButton: true,
  hideNewRegisterButton: false,
}

Header.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  title: PropTypes.any.isRequired,
  hasBackButton: PropTypes.bool,
  hideNewRegisterButton: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  hasElevation: PropTypes.bool,
}

export default Header
