import { getDefault } from './defaultConfigs'
import { CountryId } from '../../../models/country-id'
import { CompanyId } from '../../../models/company-id'
import { TENANT_ID } from '../tenantId'

export const getNaturaColombia = () => ({
  ...getDefault(),
  tenantId: TENANT_ID.NATURA_COLOMBIA,
  country: {
    alpha2Code: CountryId.COLOMBIA,
    alpha3Code: 'COL',
    language: 'es',
  },
  company: {
    name: 'NATURA',
    id: CompanyId.NATURA,
    logout: {
      buttonLinkHref: '/login?country=COL&company=NATURA',
      externalLink: false,
    },
  },
})
