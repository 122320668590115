import React from 'react'
import Cognito from './cognito'
import RHSSO from '../login/Login'
import Loading from '../../components/Loading'

import { useCognito } from '../../hooks/useCognito'

const Auth = (props) => {
  const { isEnable, loading } = useCognito()

  const renderLogin = () => {
    if (loading) {
      return <Loading />
    }

    if (!isEnable) {
      return (<RHSSO {...props} />)
    }

    return (<Cognito {...props} />)
  }

  return renderLogin()
}

export default Auth
