import React from 'react'
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import Login from '../screens/auth'
import { getToken } from '../helpers/auth'
import RestrictedRoutes from './RestrictedRoutes'
import { Logout } from '../screens/logout/Logout'

function RoutesContainer() {
  return (
    <Switch>
      <Route path="/login" render={(props) => (<Login {...props} />)} />
      <Route path="/logout" render={() => (<Logout />)} />
      <RestrictedRoute path="/" component={RestrictedRoutes} />
    </Switch>
  )
}

const RestrictedRoute = ({ component: Component, ...rest }) => {
    const token = getToken()
    return (
      <Route
        {...rest}
        render={(props) => (token ? (
          <Component {...props} />
      ) : (
        <Redirect
          to={{
              pathname: '/login',
              state: { from: props.location },
              search: props.location.search,
            }}
        />
        ))}
      />
  )
}

export default RoutesContainer
