import React, { useEffect } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useLocation, useHistory } from 'react-router-dom'
import { getScopedPath } from '../../routes/RestrictedRoutes'
import { ROOT_ROUTE } from '../../Routes'
import { ScreenLoader } from '../../components/ScreenLoader/ScreenLoader'

const Login = () => {
  const { keycloak } = useKeycloak()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (!keycloak.authenticated) {
      const countryFromStorage = localStorage.getItem('country')
      const query = new URLSearchParams(location.search)
      const country = query.get('country') ? query.get('country') : countryFromStorage

      keycloak.login({
        redirectUri: `${window.location.origin}${getScopedPath()}`,
        loginHint: `CLIENT_CONFIG_CODE=${country || 'PE'}`,
      })
    } else {
      history.push(ROOT_ROUTE)
    }
  }, [keycloak, location.search])

  return <ScreenLoader />
}

export default Login
