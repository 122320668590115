import useSWR from 'swr'
import { getBffApiGatewayConnection } from '../../services/config'

export const makeGetProspectDetailsKey = (personId) => `/accounts/register-reports/v1/people/${personId}`

export function useGetProspectDetailsSWR(personId) {
  const { data, error } = useSWR(
    makeGetProspectDetailsKey(personId),
    async (url) => {
      const connection = await getBffApiGatewayConnection()
      const response = await connection.get(url)
      return response.data
    },
  )

  return {
    userData: data,
    isLoading: !error && !data,
    hasError: Boolean(error),
  }
}
