/* eslint-disable no-unneeded-ternary */
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  getCognitoAccessToken,
  STORAGE_COMPANY_KEY,
  STORAGE_COUNTRY_KEY,
  STORAGE_SSO_TOKEN_KEY,
} from '../../../helpers/auth'
import { ROOT_ROUTE, AUTH_ROUTE } from '../../../Routes'
import { getAccessTokenStart } from '../../../redux/auth/actions'
import { ScreenLoader } from '../../../components/ScreenLoader/ScreenLoader'
import { getCountryConfiguration } from '../../../hooks/useTenantConfigs'
import { getConfigsMFEFirebase } from '../../../helpers/mfeConfisFirebase'
import { CountryId } from '../../../models/country-id'
import { CompanyName } from '../../../models/company-name'

const Cognito = () => {
  const token = getCognitoAccessToken()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isAuthenticated()) {
      history.push(ROOT_ROUTE)
    } else {
      authenticate()
    }
  }, [location.search])

  const isAuthenticated = () => (token && typeof token !== 'undefined' ? true : false)

  const authenticate = () => {
    try {
      const ssoToken = getSSOToken()
      localStorage.setItem(STORAGE_SSO_TOKEN_KEY, ssoToken)
      dispatch(getAccessTokenStart(ssoToken))
    } catch (error) {
      redirectToHostedUi()
    }
  }

  const getSSOToken = () => {
    const query = new URLSearchParams(location.search)
    const ssoToken = query.get('sso_token') ?? localStorage.getItem(STORAGE_SSO_TOKEN_KEY)
    if (!ssoToken) {
      throw new Error('cannot get sso token')
    }
    return ssoToken
  }

  const redirectToHostedUi = () => {
    const clientId = getConfigsMFEFirebase('REACT_APP_CLIENT_ID_COGNITO') || process.env.REACT_APP_CLIENT_ID_COGNITO

    const {
      country: { language, alpha2Code },
      company: { name },
    } = getConfigs()
    const countryName = alpha2Code.toLowerCase()
    const companyName = name.toLowerCase()

    const redirectUri = `${window.location.origin}${AUTH_ROUTE}`

    const urlFirebase = getConfigsMFEFirebase('AUTH_URL') || process.env.REACT_APP_HOSTED_UI_AUTHENTICATION_URL

    const deviceIdParam = getDeviceIdParam(countryName, companyName)

    window.location.href = `${urlFirebase}?client_id=${clientId}&country=${countryName}&language=${language}&company=${companyName}&redirect_uri=${redirectUri}${deviceIdParam}`
  }

  const getConfigs = () => {
    const country = localStorage.getItem(STORAGE_COUNTRY_KEY)
    const company = Number(localStorage.getItem(STORAGE_COMPANY_KEY))

    return getCountryConfiguration(company, country)
  }

  const getDeviceIdParam = (countryName, companyName) => (countryName.toLowerCase() === CountryId.BRAZIL.toLowerCase() && companyName.toLowerCase() === CompanyName.AVON.toLowerCase() ? '&device_id=12345556' : '')

  return <ScreenLoader />
}

export default Cognito
