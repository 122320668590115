import substatusMessages from './substatusMessages'
import nationalityMessages from './nationalityMessages'
import statusMessages from './statusMessages'
import digitalStoreMessages from './digitalStoreMessages'

export default {
  admissionDate: 'Fecha de admisión',
  age: 'Edad',
  allRegisters: 'Todos los registros',
  alreadyGotInTouch: 'Ya me puse en contacto',
  alreadyMadeContact: 'Ya Contactado',
  alternativeAddress: 'Dirección Alternativa',
  applyFilter: 'Filtrar',
  approve: 'Aprobar',
  approved: 'Aprobado',
  approveRegistrationDialogTitle: 'Aprobar Registro',
  approveRegistrationDialogMessage:
    '¿Está seguro de que desea aprobar el registro de <strong>{name}</strong>?',
  attachments: 'Documentos adjuntos',
  attachmentsAddress: 'Comprobante de domicillio',
  attachmentsCelulaCiudadaniaBack: '{doc} - Dorso',
  attachmentsCelulaCiudadaniaFront: '{doc} - Frente',
  attachmentsCelulaExtranjeriaBack: '{doc} - Dorso',
  attachmentsCelulaExtranjeriaFront: '{doc} - Frente',
  attachmentsDniArgentinaBack: '{doc} - Dorso',
  attachmentsDniArgentinaFront: '{doc} - Frente',
  attachmentsIneMexicoBack: '{doc} - Dorso',
  attachmentsIneMexicoFront: '{doc} - Frente',
  attachmentsDocumentForeignBack: '{doc} - Dorso',
  attachmentsDocumentForeignFront: '{doc} - Frente',
  attachmentsDniPeruDocumentFront: '{doc} - Frente',
  attachmentsDniPeruDocumentBack: '{doc} - Dorso',
  attachmentsPassportMexico: '{doc}',
  attachmentsIntention: 'Fotografía de la intencion',
  attachmentsProofEconomicLife: 'Prova de  vida econômica',
  attachmentsTerms: 'Términos y Condiciones',
  beginnings: 'Inícios',
  birthday: 'Fecha de nacimiento',
  cancel: 'Cancel',
  callToPerson: 'Llama a {name}',
  cellphone: 'Teléfono (Móvil)',
  city: 'Comuna',
  citizenCard: 'Cédula de Ciudadanía',
  cologne: 'Colonia',
  commercialStructure: 'Estructura comercial',
  complement: 'Complemento',
  consultantCode: 'Código CN',
  consultantRegistration: 'Registro de Consultor',
  contact: 'Contacto',
  contactNumber: 'Teléfono',
  country: 'País',
  cpf: 'CPF',
  credit: 'Crédito',
  creditLinePostPaid: 'Crédito',
  dateOfBirth: 'Fecha de nacimiento',
  decideLater: 'Decide después',
  deliveryAddress: 'Dirección de Entrega',
  department: 'Departamento',
  dropouts: 'Reprobados/Desistencia',
  district: 'Distrito',
  dni: 'DNI',
  dniArgentina: 'DNI',
  documentNumber: 'Nº de documento',
  document: 'Documento',
  documents: 'Documentos',
  edit: 'Editar Registro',
  editButton: 'Editar',
  email: 'E-mail',
  errorTitle: 'Lo sentimos, pero tenemos un problema con nuestro sistema…',
  errorSubtitle: 'Por favor, inténtelo de nuevo más tarde.',
  establishment: 'Establecimiento',
  femaleGender: 'Femenino',
  filterByCreditLine: 'Modalidad de Pago',
  status: 'Estado',
  filters: 'Filtros',
  foreignCard: 'Cédula de Extranjería',
  foreignRegistry: 'Carnet de Extranjeria',
  gender: 'Género',
  group: 'Grupo',
  helloStr: 'Hola! És o report app',
  homeTime: 'Tiempo de Casa',
  indicator: 'Indicante',
  leader: 'Lider',
  level1: 'Provincia',
  level2: 'Partido/Municipio',
  level3: 'Localidad',
  level4: 'Barrio',
  lineNumber: 'Teléfono fijo',
  logout: 'Salir',
  mailingAddress: 'Dirección Residencial',
  maleGender: 'Masculino',
  mobileNumber: 'Teléfono (Móvil)',
  moreDetails: 'Más detalles',
  moveToNewRegisters: 'Mover a nuevos registros',
  myRegisters: 'Mis Registros',
  myCad: 'MyCad',
  name: 'Nombre',
  nationalRegistry: 'DNI',
  nationality: 'Nacionalidad',
  naturaBrand: 'Imagen con la marca Natura',
  avonBrand: 'Imagen con la marca Avon',
  naturaReports: 'Informes Natura',
  avonReports: 'Informes Avon',
  neighborhood: 'Barrio',
  newRegisters: 'Nuevos Registros',
  no: 'no',
  noNewRegisters: 'No hay nuevos registros',
  noRegisters: 'No hay registros',
  noRegisteredAddress: 'No hay domicilio registrado.',
  noRegistersContacted: 'No hay registros ya contactados',
  noResultsOnCommercialStructure:
    'No se encontraron resultados en su estructura comercial',
  rutChile: 'RUT',
  number: 'Número',
  origin: 'Origen',
  other: 'Otro',
  otherGender: 'Otro',
  paymentPlan: 'Modalidad de Pago',
  passport: 'Pasaporte',
  passportMalasya: 'Pasaporte Malayo',
  personCode: 'Código Persona',
  sellerId: 'Código',
  personalData: 'Datos Personales',
  prePaid: 'Pre-pago',
  province: 'Provincia',
  references: 'Referencias',
  region: 'Región',
  registerOn: 'Registro en',
  registerReports: 'Reporte de registros',
  registerStatus: 'Estado de Registro',
  registrant: 'Cadastrante',
  registrationDenied: 'Registro Denegado',
  registerAllocatedOnCommercialStructure:
    'Registro asignado en estructura comercial: ',
  registerButtonLinkLabel: 'Nuevo Nombramiento',
  registerOriginGlobal: 'Registro Global',
  registerOriginMinegocio: 'MiNegocio',
  registerOriginUndefined: 'Indefinido',
  reject: 'Rechazar',
  rejectRegistration: 'Rechazar Registro',
  rejectRegistrationDialogTitle: 'Rechazar Registro',
  rejectRegistrationDialogMessage:
    '¿Está seguro de que desea rechazar el registro de  <strong>{name}</strong>?',
  rejectionReason: 'Motivo de rechazo',
  restart: 'Reinício',
  resultsOutsideStructure: 'Resultados fuera de la estructura comercial',
  rg: 'RG',
  searchByDocument: 'Busque el documento',
  selectDocument: 'Selecciona documento',
  selectGroup: 'Selecciona grupo',
  selfRegistration: 'Auto Cadastro',
  signUpStatus: 'Estado de registro',
  state: 'Estado',
  street: 'Calle',
  streetAddressLine1: 'Dirección línea 1',
  streetAddressLine2: 'Dirección línea 2',
  suggestedForStart: 'En proceso de registro',
  termsAndConditionsRemark: 'Términos y condiciones (observación)',
  unknownDocumentType: 'Documento',
  waitingFirstOrder: 'Esperando 1er pedido',
  years: '{age} años',
  yes: 'sí',
  yesImSure: 'Sí, estoy seguro',
  zipCode: 'Código Postal',
  zipcode: 'Código Postal', // this key came from BFF without camelCase
  reprocessButtonLabel: 'Reprocesar',
  reprocessDialogTitle: '¿Seguro que quieres reprocesar?',
  reprocessDialogText: 'Esto volverá a procesar este registro, ¿está seguro de que desea continuar?',
  reprocessDialogBack: 'Volver',
  reprocessDialogConfirm: 'Si, estoy segura',
  curp: 'CURP',
  ineMexico: 'INE',
  stateBirth: 'Estado de Nacimiento',
  firstName: 'Nombre(s)',
  middleName: 'Apellido Materno',
  lastName: 'Apellido Paterno',
  ...statusMessages,
  ...substatusMessages,
  ...nationalityMessages,
  ...digitalStoreMessages,
}
