import { useEffect, useState } from 'react'
import { getLocaleInfoFromStorage } from '../../helpers/auth'
import { CompanyId } from '../../models/company-id'
import {
  getAvonMexico,
  getNaturaMexico,
  getNaturaArgentina,
  getNaturaBrazil,
  getNaturaChile,
  getNaturaColombia,
  getNaturaPeru,
  getNaturaMalaysia,
  getAvonArgentina,
  getAvonBrazil,
} from './configs'

const tenantConfigs = {
  [CompanyId.AVON]: [
    getAvonMexico,
    getAvonArgentina,
    getAvonBrazil,
  ],
  [CompanyId.NATURA]: [
    getNaturaArgentina,
    getNaturaBrazil,
    getNaturaPeru,
    getNaturaChile,
    getNaturaColombia,
    getNaturaMalaysia,
    getNaturaMexico,
  ],
}

export const getCountryConfiguration = (company, country) => {
  const id = getValidCompanyId(company)

  const companyConfiguration = tenantConfigs[id]

  if (!companyConfiguration) {
    throw new Error(`Company ${company} not configured`)
  }

  const countryConfiguration = companyConfiguration.find((getConfiguration) => {
    const configs = getConfiguration()
    return configs.country.alpha2Code === country || configs.country.alpha3Code === country
  })

  if (!countryConfiguration) {
    throw new Error(`Country ${country} not configured`)
  }

  return countryConfiguration()
}

const getValidCompanyId = (company) => {
  if (typeof company === 'number') { return company }

  const id = CompanyId?.[company]

  if (!id) {
    throw new Error(`Company id ${id} not found`)
  }

  return id
}

export const useTenantConfigs = () => {
  const [result, setResult] = useState({ isLoading: true })

  useEffect(() => {
    getLocaleInfoFromStorage()
      .then(({ company, country }) => {
        const result = getCountryConfiguration(company, country)
        setResult({
          isLoading: false,
          configs: result,
        })
      })
      .catch((e) => {
        setResult({ isLoading: false, error: e.message })
      })
  }, [])

  return result
}
