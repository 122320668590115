import { getDefault } from './defaultConfigs'
import { CountryId } from '../../../models/country-id'
import { CompanyId } from '../../../models/company-id'

export const getNaturaMalaysia = () => ({
  ...getDefault(),
  country: {
    alpha2Code: CountryId.MALAYSIA,
    alpha3Code: 'MYS',
    language: 'en',
  },
  company: {
    name: 'NATURA',
    id: CompanyId.NATURA,
    logout: {
      buttonLinkHref: '/login?country=MYS&company=NATURA',
      externalLink: false,
    },
  },
})
