import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'

import { Spacing } from '@naturacosmeticos/natds-web'
import IconButton from '../../../components/Buttons/IconButton'
import Loading from '../../../components/Loading'
// eslint-disable-next-line import/no-cycle
import ErrorArea from '../../../components/Error/ErrorInfo'
// eslint-disable-next-line import/no-cycle
import ScreenContainer from '../../../components/ScreenContainer/ScreenContainer'
import { snakeToCamel } from '../../../helpers/common'
import { useResponsiveness } from '../../../hooks/responsive'
import CollaboratorRegistrationCard from './CollaboratorRegistrationCard'
import EditButton from './EditButton/EditButton'
import PersonalDataCard from './PersonalDataCard'
import DocumentsCard from './DocumentsCard/DocumentsCard'
import AddressCard from './AddressCard'
import ConsultantRegistrationCard from './ConsultantRegistrationCard/ConsultantRegistrationCard'
import ContactCard from './ContactCard'
import { useGetProspectDetailsSWR } from '../../../hooks/swrServices/useGetProspectDetailsSWR'
import Reprocess from './reprocess'
import SendLinkButton from './SendLink/SendLinkButton'
import { useStyles } from './prospect-details.styles'
import { useTenantConfigs } from '../../../hooks/useTenantConfigs'

export default () => {
  const { id, status } = useParams()
  const { userData, isLoading: loading, hasError } = useGetProspectDetailsSWR(id)
  const { isMobile, isDesktop } = useResponsiveness()
  const history = useHistory()
  const subStatus = userData?.substatus
  const classes = useStyles()
  const { configs } = useTenantConfigs()

  const EditButtonLinkedToProspectForm = () => {
    const { isEditable = false } = userData || {}
    return isEditable ? <EditButton personId={id} /> : null
  }

  return (
    <ScreenContainer
      hasDrawer
      headerButtons={(
        <>
          <EditButtonLinkedToProspectForm />
          <Reprocess subStatus={subStatus} />
        </>
      )}
      title={
        isMobile ? (
          userData?.name
        ) : (
          <FormattedMessage id={snakeToCamel(status)} />
        )
      }
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {hasError ? (
        <ErrorArea />
      ) : loading ? (
        <Loading />
      ) : (
        <>
          {isDesktop && (
            <AppBar
              elevation={1}
              data-testid="prospectNameAppBar"
              color="inherit"
              classes={{ root: classes.appBar }}
              position="static"
            >
              <Toolbar variant="dense" className={classes.prospectToolbar}>
                <IconButton
                  edge="start"
                  aria-label="back"
                  onClick={() => history.goBack()}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Typography
                  className={classes.prospectName}
                  variant="h6"
                  color="inherit"
                >
                  {userData?.name}
                </Typography>

                <Spacing display="flex">
                  <EditButtonLinkedToProspectForm />
                  <Reprocess subStatus={subStatus} />
                  { configs?.profile?.shouldDisplaySendLinkButton && (
                    <SendLinkButton subStatus={subStatus} classNameBtn={classes.propectButton} />
                  )}
                </Spacing>
              </Toolbar>
            </AppBar>
          )}
          <Grid container spacing={1} classes={{ root: classes.gridContainer }}>
            <PersonalDataCard userData={userData} />
            <ContactCard userData={userData} personId={id} />
            <DocumentsCard
              userData={userData}
              cardTitleId="documents"
              cardTitleDefaultMessage="Documents"
            />
            <AddressCard
              cardTitleId="mailingAddress"
              cardTitleDefaultMessage="Mailing Address"
              addressData={
                userData.addresses ? userData.addresses.MAILING : null
              }
            />
            <CollaboratorRegistrationCard userData={userData} />
            <ConsultantRegistrationCard userData={userData} />
          </Grid>
        </>
      )}
    </ScreenContainer>
  )
}
