import substatusMessages from './substatusMessages'
import nationalityMessages from './nationalityMessages'
import statusMessages from './statusMessages'

export default {
  admissionDate: 'Data de admissão',
  age: 'Idade',
  allRegisters: 'Todos os cadastros',
  alreadyGotInTouch: 'Já entrei em contato',
  alreadyMadeContact: 'Já fiz contato',
  alternativeAddress: 'Endereço Alternativo',
  applyFilter: 'Filtrar',
  approve: 'Aprovar',
  approved: 'Aprovado',
  approveRegistrationDialogTitle: 'Aprovar Cadastro',
  approveRegistrationDialogMessage:
    'Tem certeza de que deseja aprovar o cadastro de <strong>{name}</strong>?',
  attachments: 'Anexos',
  attachmentsAddress: 'Prova de endereço',
  attachmentsCelulaCiudadaniaBack: '{doc} - Verso',
  attachmentsCelulaCiudadaniaFront: '{doc} - Frente',
  attachmentsCelulaExtranjeriaBack: '{doc} - Verso',
  attachmentsCelulaExtranjeriaFront: '{doc} - Frente',
  attachmentsDocumentBack: '{doc} - Verso',
  attachmentsDocumentForeignBack: '{doc} - Verso',
  attachmentsDocumentForeignFront: '{doc} - Frente',
  attachmentsDocumentFront: '{doc} - Frente',
  attachmentsIntention: 'Foto da intenção',
  attachmentsProofEconomicLife: 'Prova financeira',
  attachmentsTerms: 'Termos e Condições',
  beginnings: 'Inícios',
  birthday: 'Data de Nascimiento',
  cancel: 'Cancel',
  callToPerson: 'Ligar para {name}',
  cellphone: 'Celular',
  city: 'Cidade',
  citizenCard: 'Cédula de Cidadania',
  cpf: 'CPF',
  commercialStructure: 'Estrutura comercial',
  complement: 'Complemento',
  consultantCode: 'Código CN',
  consultantRegistration: 'Registro de Consultor',
  contact: 'Contato',
  contactNumber: 'Número para contato',
  country: 'País',
  credit: 'Crédito',
  creditLinePostPaid: 'Pós-pago',
  dateOfBirth: 'Data de Nascimento',
  deliveryAddress: 'Endereço de Entrega',
  department: 'Departamento',
  dropouts: 'Reprovados/Desistência',
  district: 'Distrito',
  decideLater: 'Decidir Depois',
  documentNumber: 'Nº do Documento',
  document: 'Documento',
  documents: 'Documentos',
  edit: 'Editar cadastro',
  editButton: 'Editar',
  email: 'E-mail',
  errorTitle: 'Desculpe, mas estamos com problema em nosso sistema…',
  errorSubtitle: 'Por favor, tente novamente mais tarde.',
  establishment: 'Estabelecimento',
  femaleGender: 'Feminino',
  filterByCreditLine: 'Modalidade de Pagamento',
  status: 'Status',
  filters: 'Filtros',
  foreignRegistry: 'RNE',
  foreignCard: 'Cédula Estrangeira',
  gender: 'Gênero',
  group: 'Grupo',
  helloStr: 'Olá! Este é o Reports app',
  homeTime: 'Tempo de Casa',
  indicator: 'Indicante',
  leader: 'Líder',
  lineNumber: 'Telefone Fixo',
  logout: 'Sair',
  mailingAddress: 'Endereço Residencial',
  maleGender: 'Masculino',
  mobileNumber: 'Celular',
  moreDetails: 'Mais detalhes',
  moveToNewRegisters: 'Mover para novos registros',
  myRegisters: 'Meus Cadastros',
  myCad: 'MyCad',
  name: 'Nome',
  nationalRegistry: 'Registro Nacional',
  nationality: 'Nacionalidade',
  naturaBrand: 'Imagem com a Marca da Natura',
  avonBrand: 'Imagem com a Marca da Avon',
  naturaReports: 'Relatórios Natura',
  avonReports: 'Relatórios Avon',
  neighborhood: 'Bairro',
  newRegisters: 'Novos Cadastros',
  no: 'não',
  noRegisters: 'Não há cadastros',
  noNewRegisters: 'Não há novos cadastros',
  noRegisteredAddress: 'Não há dados registrados',
  noRegistersContacted: 'Não há cadastros já contactados',
  noResultsOnCommercialStructure:
    'Nenhum resultado encontrado em sua estrutura comercial',
  number: 'Número',
  origin: 'Origem',
  otherGender: 'Outro',
  paymentPlan: 'Forma de Pagamento',
  passport: 'Passaporte',
  passportMalasya: 'Passaporte Malaio',
  personCode: 'Código de Pessoa',
  sellerId: 'Codigo',
  personalData: 'Dados Pessoais',
  prePaid: 'Pré-pago',
  province: 'Provincia',
  references: 'Referências',
  region: 'Região',
  registerOn: 'Cadastro em',
  registerReports: 'Relatório de cadastros',
  registerStatus: 'Status do Registro',
  registrant: 'Cadastrante',
  registrationDenied: 'Registro Negado',
  registerAllocatedOnCommercialStructure:
    'Cadastro alocado na estrutura comercial: ',
  registerOriginGlobal: 'Cadastro Global',
  registerOriginMinegocio: 'MiNegocio',
  registerOriginUndefined: 'Indefinido',
  registerButtonLinkLabel: 'Novo Registro',
  reject: 'Recusar',
  rejectRegistration: 'Recusar Cadastro',
  rejectRegistrationDialogTitle: 'Recusar Cadastro',
  rejectRegistrationDialogMessage:
    'Tem certeza de que deseja recusar o cadastro de <strong>{name}</strong>?',
  rejectionReason: 'Motivo da rejeição',
  restart: 'Reinício',
  resultsOutsideStructure: 'Resultados fora da estrutura comercial',
  rg: 'RG',
  searchByDocument: 'Busque pelo documento',
  selectDocument: 'Selecione o documento',
  selectGroup: 'Selecione o Grupo',
  selfRegistration: 'Auto Cadastro',
  signUpStatus: 'Status do cadastro',
  state: 'Estado',
  street: 'Rua',
  streetAddressLine1: 'Endereço linha 1',
  streetAddressLine2: 'Endereço linha 2',
  suggestedForStart: 'Sugeridas para início',
  termsAndConditionsRemark: 'Termos e Condições(observação)',
  unknownDocumentType: 'Documento',
  waitingFirstOrder: 'Aguardando 1º pedido',
  years: '{age} anos',
  yes: 'sim',
  yesImSure: 'Sim, tenho certeza',
  zipCode: 'CEP',
  reprocessButtonLabel: 'Reprocessar',
  reprocessDialogTitle: 'Tem certeza que deseja reprocessar?',
  reprocessDialogText: 'Isto fará com que o cadastro seja reprocessado, tem certeza que deseja continuar?',
  reprocessDialogBack: 'Cancelar',
  reprocessDialogConfirm: 'Sim, tenho certeza',
  stateBirth: 'Estado de Nascimento',
  ...statusMessages,
  ...substatusMessages,
  ...nationalityMessages,
}
