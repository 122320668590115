import React, { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import i18nMessages from './locales/i18nMessages'
import { getAppLocale } from './helpers/mfeLocaleConfig'
import Loading from './components/Loading'

export default ({ children }) => {
  const [loadingLocale, setLoadingLocale] = useState(true)
  const [locale, setLocale] = useState('en')

  const getSystemLocale = async () => {
    setLoadingLocale(true)
    const locale = await getAppLocale()
    setLocale(locale)
    setLoadingLocale(false)
  }

  useEffect(() => {
    getSystemLocale()
  }, [])

  if (loadingLocale) return <Loading />

  return (
    <IntlProvider locale={locale} messages={i18nMessages[locale]}>
      {children}
    </IntlProvider>
  )
}
