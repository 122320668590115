import * as jwt from 'jsonwebtoken'
import { keycloak } from '../App.keycloak'
import { LOCAL_STORAGE_PREFIX } from '../Constants'
import { getAllToggles } from '../services/remote-config'
import { CompanyId } from '../models/company-id'
import { getBffApiGatewayConnection } from '../services/config'

export const STORAGE_USER_INFO_KEY = 'uinfo'
export const LOCAL_STORAGE_AUTH = `${LOCAL_STORAGE_PREFIX}auth`
export const STORAGE_ACCESS_TOKEN_KEY = 'accessToken'
export const STORAGE_ID_TOKEN_KEY = 'idToken'
export const STORAGE_EXPIRESIN_KEY = 'expiresIn'
export const STORAGE_SSO_TOKEN_KEY = 'ssoToken'
export const STORAGE_COUNTRY_KEY = 'country'
export const STORAGE_COUNTRY_ALPHA3_CODE_KEY = 'country_alpha3'
export const STORAGE_COMPANY_KEY = 'company'

export const getUserAuthFromLocalStorage = () => {
  const authItem = localStorage.getItem(LOCAL_STORAGE_AUTH)
  if (authItem) {
    return JSON.parse(authItem)
  }

  return {}
}

export const getToken = () => {
  if (localStorage.getItem(LOCAL_STORAGE_AUTH)) {
    const { accessTokenNaturaWeb } = getUserAuthFromLocalStorage()

    return accessTokenNaturaWeb
  }

  if (isAuthenticatedByKeycloak()) {
    return keycloak.token
  }

  if (isAuthenticatedByCognito()) {
    return getCognitoAccessToken()
  }
}

export const isAuthenticatedByCognito = () => {
  const tokenFromLocalStorage = localStorage.getItem(STORAGE_ACCESS_TOKEN_KEY)

  return tokenFromLocalStorage
}

export const getCognitoAccessToken = () => {
  const tokenFromLocalStorage = localStorage.getItem(STORAGE_ACCESS_TOKEN_KEY)
  const expiresInFromLocalStorage = localStorage.getItem(STORAGE_EXPIRESIN_KEY)

  if (isValidToken(tokenFromLocalStorage, expiresInFromLocalStorage)) {
    return tokenFromLocalStorage
  }
}

export const isValidToken = (token, expiresIn) => {
  if (!token || !expiresIn) {
    return false
  }

  const actualDate = new Date()
  const expiryDate = new Date(expiresIn * 1000)

  return expiryDate.getTime() >= actualDate.getTime()
}

export const removeUserInfo = () => {
  localStorage.removeItem(STORAGE_USER_INFO_KEY)
  localStorage.removeItem(LOCAL_STORAGE_AUTH)
}

export const updateUserInfo = (userInfo) => {
  localStorage.setItem(STORAGE_USER_INFO_KEY, JSON.stringify(userInfo))
}

export const getUserInfo = async () => {
  if (localStorage.getItem(LOCAL_STORAGE_AUTH)) {
    return getUserAuthFromLocalStorage()
  }

  const storageUserInfo = localStorage.getItem(STORAGE_USER_INFO_KEY)
  if (storageUserInfo) {
    return JSON.parse(storageUserInfo)
  }

  const userInfo = await getUserInfoByKeycloak()
  return userInfo
}

export const getLocaleInfoFromStorage = async () => {
  const userInfo = await getUserInfo()

  if (userInfo?.country && userInfo?.company) {
    return { company: userInfo?.company, country: userInfo?.country }
  }

  const companyId = Number(localStorage.getItem(STORAGE_COMPANY_KEY)) || null
  const countryId = localStorage.getItem(STORAGE_COUNTRY_KEY)

  return { company: companyId, country: countryId }
}

export const createUserInfoByCognito = (accessToken, idToken) => {
  const personId = getPersonIdFromToken(accessToken, idToken)
  const country = localStorage.getItem(STORAGE_COUNTRY_KEY)
  const company = localStorage.getItem(STORAGE_COMPANY_KEY)

  validatePersonId(personId)

  return {
    country,
    company: Number(company),
    personId,
  }
}

export const getPersonIdFromToken = (accessToken, idToken) => {
  const { SHOULD_USE_ID_TOKEN } = getHostedUiToggles()

  if (SHOULD_USE_ID_TOKEN) {
    return jwt.decode(idToken, { complete: true }).payload?.preferred_username
  }

  return jwt.decode(accessToken, { complete: true }).payload?.username
}

const validatePersonId = (personId) => {
  if (typeof personId === 'undefined') {
    throw new Error("cannot get user's person id")
  }
}

export const getUserInfoByKeycloak = async () => {
  const userInfo = {}

  if (isAuthenticatedByKeycloak()) {
    const userInfoFromToken = await keycloak.loadUserInfo()
    userInfo.company = userInfoFromToken.company
    userInfo.country = userInfoFromToken.country
    userInfo.personId = userInfoFromToken.personId
  }

  return userInfo
}

export const isAuthenticatedByKeycloak = () => keycloak.token && keycloak.authenticated

export const getHostedUiToggles = () => {
  const country = localStorage.getItem(STORAGE_COUNTRY_ALPHA3_CODE_KEY)
  const company = Number(localStorage.getItem(STORAGE_COMPANY_KEY)) === CompanyId.NATURA ? 'NATURA' : 'AVON'
  const toggles = getAllToggles()

  const hostedUiToggles = toggles.AUTH?.[company]?.[country]

  validateHostedUiToggle(hostedUiToggles)

  return hostedUiToggles
}

const validateHostedUiToggle = (toggle) => {
  if (typeof toggle === 'undefined') {
    throw new Error('toggle not found')
  } else if (typeof toggle === 'object' && !Object.keys(toggle).length) {
    throw new Error('toggle object is empty')
  }
}

export const GetPersonIdFromPeopleContext = async (userInfo) => {
  const { personId } = await getUserInfo()

  const connection = await getBffApiGatewayConnection()
  const response = await connection.get(
    `/accounts/register-reports/v1/user/personId/${personId}`,
  )

  userInfo.personId = response.data.personId

  updateUserInfo(userInfo)

  return userInfo
}
