import { getUserInfo, STORAGE_COMPANY_KEY, STORAGE_COUNTRY_KEY } from '../../helpers/auth'
import { getCountryConfiguration } from '../../hooks/useTenantConfigs'

export const SOURCE_SYSTEM = '50'

export const getBaseHeaders = async () => {
  const { country, loginId, company } = await getUserInfo()

  const headers = {
    country,
    company,
    sourceSystem: SOURCE_SYSTEM,
    'x-api-key': window._env_.REGISTER_REPORTS_BFF_API_KEY,
  }

  if (loginId) {
    headers.loginId = loginId
  }

  return headers
}

export const getRecoverSessionHeaders = async () => {
  const { country, loginId, company } = await getUserInfo()

  const countryConfig = localStorage.getItem(STORAGE_COUNTRY_KEY)
  const companyConfig = Number(localStorage.getItem(STORAGE_COMPANY_KEY))

  const {
    company: { name },
  } = getCountryConfiguration(companyConfig, countryConfig)

  const headers = {
    country,
    company,
    sourceSystem: SOURCE_SYSTEM,
    'x-api-key': await getRegisterReportsBffApiKeyCompany(name) || window._env_.REGISTER_REPORTS_BFF_API_KEY,
  }

  if (loginId) {
    headers.loginId = loginId
  }

  return headers
}

export const getRegisterReportsBffApiKeyCompany = async (companyName) => (companyName === 'NATURA'
    ? window._env_.REGISTER_REPORTS_BFF_API_KEY_NATURA
    : window._env_.REGISTER_REPORTS_BFF_API_KEY_AVON)

const buildConnectionDefaultHeaders = async () => {
  const defaultHeaders = await getBaseHeaders()
  const { personId } = await getUserInfo()

  return {
    ...defaultHeaders,
    personId,
  }
}

const buildRecoverSessionHeaders = async () => {
  const defaultHeaders = await getRecoverSessionHeaders()
  const { personId } = await getUserInfo()

  return {
    ...defaultHeaders,
    personId,
  }
}

export const BffApiGatewayHeaders = {
  connection: () => buildConnectionDefaultHeaders(),
  recoverSession: () => buildRecoverSessionHeaders(),
}
