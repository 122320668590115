import React from 'react'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'

import { FormattedMessage } from 'react-intl'
import CustomCard from '../Card'
import CustomListItem from '../CustomListItem'
import { snakeToCamel } from '../../../../helpers/common'
import AttachmentList from './AttachmentList'
import DocumentList from './DocumentList'
import { useTenantConfigs } from '../../../../hooks/useTenantConfigs'

const DocumentsCard = ({ cardTitleId, cardTitleDefaultMessage, userData }) => {
  const { isLoading, configs } = useTenantConfigs()
  const shouldRenderStateBirth = configs?.profile.hasNationalityState && !isLoading
  userData.stateBirth = userData.birthStateFullName

  const fields = [
    {
      fieldId: 'nationality',
      fieldDefaultMessage: 'Nationality',
      dataIndex: 'nationality',
      render: () => userData.nationality && (
      <FormattedMessage id={snakeToCamel(userData.nationality)} />
      ),
    },
  ]

  if (shouldRenderStateBirth) {
    fields.push(
      {
        fieldId: 'stateBirth',
        fieldDefaultMessage: 'State Birth',
        dataIndex: 'stateBirth',
        render: () => userData.stateBirth && (
          <FormattedMessage id={userData.stateBirth} />
        ),
      },
    )
  }

  return (
    <Grid item xs={12} sm={6}>
      <CustomCard
        titleMessageId={cardTitleId}
        titleDefaultMessage={cardTitleDefaultMessage}
      >
        <List dense>
          <DocumentList documents={userData.documents} />
          {fields.map((field) => (
            <CustomListItem
              key={field.fieldId}
              fieldId={field.fieldId}
              fieldDefaultMessage={field.fieldDefaultMessage}
              data={field.render ? field.render() : userData[field.dataIndex]}
            />
          ))}
          <CustomListItem
            fieldId="attachments"
            fieldDefaultMessage="Attachments"
            data={(
              <AttachmentList
                attachments={userData.attachments}
              />
            )}
          />
          <CustomListItem
            fieldId="termsAndConditionsRemark"
            fieldDefaultMessage="Terms and conditions (remark)"
            data={userData.policiesAccepted}
          />
        </List>
      </CustomCard>
    </Grid>
  )
}

export default DocumentsCard
