import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { useResponsiveness } from '../../hooks/responsive'
import { PeopleTableHeader } from './people-table-header'
import TableList from '../../components/TableList/TableList'
import { desktopColumns, mobileColumns } from './people-table-columns'
import ConsultantDetailsDialog from '../ConsultantDetailsDialog/ConsultantDetailsDialog'
import ErrorArea from '../../components/Error/ErrorInfo'
import DocumentSearch from '../../components/DocumentSearch/DocumentSearch'
import { appMode } from '../../adapter'
import { getRequestCancelSource } from '../../services/config'
import { useGetPeoplePaginated } from './hooks/use-get-people-paginated'
import { FilterPeopleTableOptions } from './people-table-options'
import { getEmptyListText } from './strategies/get-empty-list-text'
import { getShouldDisableContactMadeButton } from './strategies/get-should-disable-contact-made-button'
import { getContactMadeButtonText } from './strategies/get-contact-made-button-text'

const PEOPLE_LIMIT = 20

export const PeopleTable = ({ prospectStatus, filterPeopleBy }) => {
  const { cancelToken } = getRequestCancelSource()

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [consultant, setConsultant] = useState(null)
  const [orderBy, setOrderBy] = useState('createdAt')
  const [orderDirection, setOrderDirection] = useState('desc')

  const { filters } = useSelector(({ prospectTable }) => prospectTable)

  const history = useHistory()
  const location = useLocation()

  const { isMobile } = useResponsiveness()

  const {
    people, hasError, hasNextPage, isLoading, isLoadingMore, clearAllCache, getNextPage, resetPageSize, mutate,
  } = useGetPeoplePaginated({
    peopleStatus: prospectStatus,
    peopleFilteredBy: filterPeopleBy,
    filters,
    sort: `${orderBy}_${orderDirection}`,
    limit: PEOPLE_LIMIT,
    cancelToken,
  })

  const getMorePeople = async () => {
    getNextPage()
  }

  const handleRowClick = (event, consultant) => {
    if (isMobile) {
      setIsDialogOpen(true)
      setConsultant(consultant)
    } else {
      const linkTo = appMode().paths.consultantDetailsPath(
        location,
        consultant.personId,
        consultant.isUnderApproval,
        consultant.candidateId,
      )
      history.push(linkTo)
    }
  }

  const handleSelectRow = (row) => {
    const peopleWithSelectedProperty = people.map((person) => (row.personId === person.personId ? { ...person, selected: !person.selected } : person))
    mutate(peopleWithSelectedProperty, false)
  }

  const handleSelectAllRows = (e) => {
    const isAllRowsSelected = e.target.checked

    const peopleWithSelectedProperty = people.map((person) => ({ ...person, selected: isAllRowsSelected }))
    mutate(peopleWithSelectedProperty, false)
  }

  const afterHandleMadeContact = async ({ selectedPeople }) => {
    const peopleIds = getPeopleIds({ selectedPeople })
    if (peopleIds) {
      const peopleListWithoutThoseWhoChangedHasContactedPropertyState = people.filter((person) => !peopleIds[person.personId])
      mutate(peopleListWithoutThoseWhoChangedHasContactedPropertyState, false)
      clearAllCache()
    }

    function getPeopleIds({ selectedPeople }) {
      if (!selectedPeople) return undefined
      return selectedPeople.reduce((acc, person) => ({ ...acc, [person.personId]: person.personId }), {})
    }
  }

  const orderTable = (column) => {
    setOrderBy(column.dataIndex)
    setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc')
    resetPageSize()
  }

  if (hasError) return <ErrorArea />

  const selectedRows = people.filter((person) => person.selected)

  return (
    <>
      {consultant ? (
        <ConsultantDetailsDialog
          afterHandleMadeContact={afterHandleMadeContact}
          peopleFilteredBy={filterPeopleBy}
          shouldHideContactMadeButton={getShouldDisableContactMadeButton(filterPeopleBy)}
          contactMadeButtonText={getContactMadeButtonText(filterPeopleBy)}
          consultant={consultant}
          open={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false)
            setConsultant(null)
          }}
        />
      ) : null}
      <PeopleTableHeader
        afterHandleMadeContact={afterHandleMadeContact}
        selectedRows={selectedRows}
        filteredBy={filterPeopleBy}
        shouldHideContactMadeButton={getShouldDisableContactMadeButton(filterPeopleBy)}
      />
      <DocumentSearch />
      <TableList
        columns={isMobile ? mobileColumns : desktopColumns}
        data={people}
        disableCheckboxes={isLoadingMore}
        hasNextPage={hasNextPage}
        loading={isLoading}
        loadingMore={isLoadingMore}
        onLoadMoreItems={getMorePeople}
        onOrder={orderTable}
        onRowClick={handleRowClick}
        emptyListText={getEmptyListText(filterPeopleBy)}
        onSelectAll={getShouldDisableContactMadeButton(filterPeopleBy) ? undefined : handleSelectAllRows}
        onSelectRow={getShouldDisableContactMadeButton(filterPeopleBy) ? undefined : handleSelectRow}
        orderBy={orderBy}
        orderDirection={orderDirection}
        id="prospect-table"
      />
    </>
  )
}

PeopleTable.defaultProps = {
}

PeopleTable.propTypes = {
  prospectStatus: PropTypes.string.isRequired,
  filterPeopleBy: PropTypes.oneOf(Object.values(FilterPeopleTableOptions)).isRequired,
}
