import React from 'react'
import PropTypes from 'prop-types'
import CustomListItem from '../CustomListItem'
import { snakeToCamel } from '../../../../helpers/common'
import defaultMessages from '../../../../locales/en/messages'

const DocumentList = ({ documents }) => {
  if (!documents) return null
  return (
    /* eslint operator-linebreak:0 */
    documents &&
    documents.map(({ type = 'document', number }) => (
      <CustomListItem
        key={type}
        fieldId={snakeToCamel(type)}
        fieldDefaultMessage={defaultMessages[snakeToCamel(type)]}
        data={number}
      />
    ))
  )
}

DocumentList.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape()),
}

export default DocumentList
