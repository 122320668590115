import React from 'react'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import { Spacing } from '@naturacosmeticos/natds-web'
import { FormattedMessage } from 'react-intl'

import CustomCard from './Card'
import CustomListItem from './CustomListItem'
import { snakeToCamel } from '../../../helpers/common'
import SendLinkButton from './SendLink/SendLinkButton'
import { useResponsiveness } from '../../../hooks/responsive'

const CollaboratorRegistrationCard = ({ userData }) => {
  const fields = [
    {
      fieldId: 'commercialStructure',
      fieldDefaultMessage: 'Commercial Structure',
      dataIndex: 'commercialStructure',
    },
    {
      fieldId: 'status',
      fieldDefaultMessage: 'Status',
      render: () => {
        const { status } = userData
        if (!status) {
          return ' - '
        }
        const fullSnakeCaseMessageId = `STATUS_${status}`
        const fullCamelCaseMessageId = snakeToCamel(fullSnakeCaseMessageId)
        return (
          <FormattedMessage
            id={fullCamelCaseMessageId}
            defaultMessage={fullCamelCaseMessageId}
          />
        )
      },
    },
    {
      fieldId: 'registerStatus',
      fieldDefaultMessage: 'Registration Status',
      render: () => {
        const { substatus } = userData
        if (!substatus) {
          return ' - '
        }
        const fullSnakeCaseMessageId = `SUB_STATUS_${substatus}`
        const fullCamelCaseMessageId = snakeToCamel(fullSnakeCaseMessageId)
        return (
          <FormattedMessage
            id={fullCamelCaseMessageId}
            defaultMessage={fullCamelCaseMessageId}
          />
        )
      },
    },
  ]

  const { isMobile } = useResponsiveness()

  return (
    <Grid item xs={12} sm={6}>
      <CustomCard
        titleMessageId="consultantRegistration"
        titleDefaultMessage="Consultant Registration"
      >
        <List dense>
          {fields.map(({
            fieldId, fieldDefaultMessage, render, dataIndex,
          }) => (
            <CustomListItem
              key={fieldId}
              fieldId={fieldId}
              fieldDefaultMessage={fieldDefaultMessage}
              data={render ? render() : userData[dataIndex]}
            />
          ))}
        </List>

        { isMobile && (
          <Spacing mb="small" mr="tiny" style={{ float: 'right' }}>
            <SendLinkButton subStatus={userData?.substatus} />
          </Spacing>
        )}
      </CustomCard>
    </Grid>
  )
}

export default CollaboratorRegistrationCard
