import React from 'react'
import implement from 'implement-js'
import KeycloakWrapper from '../../../App.keycloak'
import { ProvidersInterface } from '../../interfaces/providers'
import { useCognito } from '../../../hooks/useCognito'
import Loading from '../../../components/Loading'

const Providers = {
  AuthenticationProvider: ({ children, ...props }) => {
    const { isEnable, loading } = useCognito()

    const render = () => {
      if (loading) {
        return <Loading />
      }

      if (!isEnable) {
        return (<KeycloakWrapper {...props}>{children}</KeycloakWrapper>)
      }

      return children
    }

    return render()
  },
}

export default implement(ProvidersInterface)(Providers)
