import React from 'react'
import {
  createGenerateClassName,
  StylesProvider,
} from '@material-ui/core/styles'
import { Provider, themes } from '@naturacosmeticos/natds-web'
import { CompanyId } from './models/company-id'
import { STORAGE_COMPANY_KEY } from './helpers/auth'

const generateClassName = createGenerateClassName({
  productionPrefix: 'naturaReportsStyle',
  disableGlobal: true,
  seed: 'naturaReportsStyle',
})

const ThemeWrapper = ({ children }) => {
  const getTheme = () => (Number(localStorage.getItem(STORAGE_COMPANY_KEY)) === CompanyId.AVON
      ? themes.avon.light : themes.natura.light)

  return (
    <StylesProvider generateClassName={generateClassName}>
      <Provider theme={getTheme()}>
        {children}
      </Provider>
    </StylesProvider>
  )
}

export default ThemeWrapper
