import { getCountryConfiguration } from '../hooks/useTenantConfigs'
import { STORAGE_COMPANY_KEY, STORAGE_COUNTRY_KEY } from './auth'
import { remoteConfig } from './firebaseConfig'

export const getConfigsMFEFirebase = (key) => {
  const country = localStorage.getItem(STORAGE_COUNTRY_KEY)
  const company = Number(localStorage.getItem(STORAGE_COMPANY_KEY))

  const {
    country: { alpha3Code },
    company: { name },
  } = getCountryConfiguration(company, country)

  const firebaseConfigsAllMFE = remoteConfig.getValue('REGISTER_REPORTS_MFE_AUTH_TOGGLE')
  return key ? JSON.parse(firebaseConfigsAllMFE.asString())[name][alpha3Code][key] : {}
}
