export const DocumentType = {
  CITIZEN_CARD: 'CITIZEN_CARD',
  FOREIGN_CARD: 'FOREIGN_CARD',
  NATIONAL_REGISTRY: 'NATIONAL_REGISTRY',
  PASSPORT: 'PASSPORT',
  FOREIGN_REGISTRY: 'FOREIGN_REGISTRY',
  PERSON_CODE: 'PERSON_CODE',
  CPF: 'CPF',
  MY_CAD: 'MY_CAD',
  PASSPORT_MALASYA: 'PASSPORT_MALASYA',
  RUT: 'RUT',
  UNKNOWN_DOCUMENT_TYPE: 'UNKNOWN_DOCUMENT_TYPE',
  DNI_ARGENTINA: 'DNI_ARGENTINA',
  CURP: 'CURP',
  INE_MEXICO: 'INE_MEXICO',
}
